.modal-compra {
  display: flex;
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 20;
  margin-top: 10rem;
  flex-direction: column;
  align-items: center;
}

.contenedor {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 360px;
  height: 65%;
  align-items: center;
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 0px 20px 1px rgba(0,0,0,0.84);
}

.close {
  position: absolute;
  right: 5px;
  top: 5px;
}

.btn-close {  
  padding: 0.5em;
  background-color: transparent;
  color: black;
  font-size: 1.5rem;
}

.info {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 80%;
  margin: 20px;
}

.info div h5 {
  font-family: Poppins-Light;
  font-weight: bold;
}

.info div h6 {
  font-family: Poppins-Light;
  font-weight: bold;
  color: red;
  text-decoration: line-through;
}

.img-muestra {
  width: 8em;
  /* box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.05); */
}

.title-m {
  font-family: Poppins-Bold;
  text-align: center;
}

.variantes-m {
  text-align: center;
}

.variantes-m img {
  width: 2.5em;
  margin: 5px;
  cursor: pointer;
  border-radius: 20px;
  border: solid 1px rgb(177, 177, 177);
}

.tallas {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}

.tallas div img {
  margin: 5px;
  cursor: pointer;
}

.unidades {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45%;
  height: 40px;
  border: solid 1px black;
  border-radius: 20px;
  margin-top: 10px;
}

.unidades input {
  width: 50%;
  outline: none;
  text-align: center;
}

.und-input::-webkit-inner-spin-button,
.und-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.unidades button {
  background-color: transparent;
  width: 15px;
}

.addCar {
  padding: 10px;
  background-color: black;
  color: white;
  border-radius: 20px;
  height: 40px;
  margin-top: 10px;
}

.prev-m {
  color: red;
  font-weight: 500;
  text-decoration: line-through;
  font-size: medium !important;
}

@media (max-width: 426px) {
  .modal-compra {
    margin-top: 12vh;
  }
  .contenedor {
    height: 80%;
  }
}