.modal-main {
  display: flex;
  align-items: center;
  width: 100%;
  position: fixed;
  flex-direction: column;
  z-index: 20;
  top: calc(50% - 10rem);
}

.content-modal {
  box-shadow: black 0px 0px 10px;
  background-color: white;
  border-radius: 10px;
  width: 500px;
}

.header-modal {
  display: flex;
  justify-content: flex-end;
  /* border-bottom: 1px solid #e0e0e0; */
  padding-right: 0.8em;
  padding-top: 0.5em;
}

.header-modal button {
  
  background-color: transparent;
  font-size: larger;
}

.content-promo {
  text-align: center;
  padding-top: 0 !important;
  padding: 2em;
}

.content-promo h2 {
  font-weight: 400;
  margin-bottom: 20px;
}



@media (max-width: 400px) {
  .content-modal {
    width: 350px;
  }
}