@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700);
#MenuFiltros {
  width: 30px;
  height: 30px;
  object-fit: contain;
  background-repeat: no-repeat;
}

#FiltrosMovil {
  height: 90vh;
  width: 100vw;
  background-color: rgba(255, 255, 255);
  box-shadow: 2px 2px 5px #999;
  z-index: 2000;
  transition: 1s;
  overflow-y: auto;
  overflow-x: hidden;
}

#Mercado {
  font-size: 1.8em;
  margin-left: 20px;
}

#BotonesRedesMenu {
  height: 40px;
  width: 40px;
}

#BotonInicio {
  margin-top: -20px;
  height: 40px;
  width: 40px;
}

/* .lupa {
  position: relative;
  top: 10px;
  left: 12px;
  height: 20px;
  width: 24px;
  display: flex;
} */

/* .zoomBuscar {
  transform: scale(1) !important;
  transition: transform 0.2s;
}

.zoomBuscar:hover {
  transform: scale(1.4) !important;
}
.zoomBuscarMovil {
  transform: scale(0.6) !important;
  transition: transform 0.2s;
}

.zoomBuscarMovil:hover {
  transform: scale(1) !important;
} */

#botonCerrar {
  position: absolute;
  top: 3%;
  right: 6%;
  font-size: 1.5em;
  z-index: 2500;
}

#headerWeb {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 12%;
  padding: 0;
  margin: 0;
  z-index: 500;
}

.FuenteBebas {
  font-family: "Bebas";
}

.FuenteHelvetica {
  font-family: "Helvetica";
}

#Flecha {
  position: absolute;
  top: 80px;
  right: -15px;
  width: 100px;
  height: 40px;
  display: flex;
}

div .cajaCarrito {
  position: absolute;
  top: 110%;
  right: 0%;
  width: 19.2rem;
  background-color: #ffffff;
  z-index: 1000;
  box-shadow: 2px 2px 5px #999;
  margin-bottom: 0px;
  padding-bottom: 0px;
  height: 275px;
}

div .cajaSesion {
  position: absolute;
  top: 50px;
  left: -100px;
  width: 200px;
  height: 100px;
  background-color: #ffffff;
  z-index: 1000;
  box-shadow: 2px 2px 5px #999;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.img-producto-carrito {
  width: 135px;
  /* height: 200px; */
  margin-left: 0px;
  margin-right: 0px;
  padding-right: 0px;
}

.cartaCarrito {
  margin-top: 10px;
}

.textoCarrito {
  margin-left: 0px;
  font-weight: bold;
  font-size: 13px;
  font-family: Helvetica;
}

#TotalCarrito {
  font-weight: bold;
  font-family: Bebas;
  font-size: 23px;
}

#botonPago {
  font-weight: bold;
  margin-top: 10px;
  margin-right: 40px;
}

.espacioPago {
  display: flex;
  align-content: center;
  justify-content: space-around;
  align-items: center;
  position: relative;
  bottom: 0;
}

.SinDecoracionHeader {
  /*Fondo y decoracion*/
  padding: 0;
  border: 0;
  background: none;
  text-decoration: none;
  margin-right: 20px;
  outline: none;
}

.SinDecoracionHeader:focus {
  /*Fondo y decoracion*/
  padding: 0;
  border: 0;
  background: none;
  text-decoration: none;
  margin-right: 20px;
  outline: none;
}

#productosCarro {
  position: absolute;
  overflow: auto;
  height: 220px;
  overflow-x: hidden;
}

.botonBorrar {
  position: relative;
  width: 29px;
  height: 35px;
}

@font-face {
  font-family: "Bebas";
  src: url(/static/media/BEBASKAI.af73723c.OTF);
  font-display: swap;
}

@font-face {
  font-family: "Helvetica";
  src: url(/static/media/HELVETICALTSTD-LIGHT.14c4e0be.OTF);
  font-display: swap;
}

#LineaCarrito {
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 13px 30px;
  background-repeat: no-repeat;
  background-size: 75px 1px;
  color: black;
}

.LineaEfecto {
  background: none;
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 4px;
  transition: background-size 0.3s;
  background-color: #ffffff00;
}

.LineaEfecto:hover,
.LineaEfecto:focus {
  background-size: 100% 4px;
}

#bienvenido {
  width: 100%;
  font-family: "Bebas";
  font-size: 25px;
}

#nadaEnElCarrito {
  font-family: "Bebas";
  font-size: 30px;
  position: relative;
  margin: 20%;
}

#BotonInicioMenuMayoristas {
  position: absolute;
  right: 0%;
  bottom: 0%;
}

.texto-IniciarSesionFiltroMayoristas {
  margin-top: 20%;
  margin-right: 10px;
  font-size: 20px;
}

.texto-AyudaFiltroMayoristas {
  margin-top: 20%;
  margin-left: 20px;
  font-size: 20px;
}

/**********************************/

#FiltrosWeb {
  display: none;
}

#Buscar {
  display: none;
}

#BarraFiltro {
  background-color: #d8d8d800;
  border: none;
  border-bottom: 1.8px solid;
  font-size: 21px;
  outline: none;
  margin-left: 2%;
  padding: 0;
  width: 140px;
}

/* #espacioLogo {
  position: absolute;
  top: 5%;
  left: 50%;
  display: flex;
  transform: translate(-50%, 0%);
  -webkit-transform: translate(-50%, 0%);
  align-items: center;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
} */
/* 
#ImagenCroatta {
  height: 70px;
  width: 170px;
  object-fit: contain;
} */

.texto-Ayuda {
  display: none;
}

.texto-IniciarSesion {
  display: none;
}

.imagen-IniciarSesion {
  display: none !important;
}

#BotonInicioMenu {
  position: absolute;
  right: 0%;
  bottom: 0%;
}
div .cajaSesionMenu {
  position: absolute;
  top: -70px;
  left: -200px;
  width: 200px;
  height: 100px;
  background-color: #ffffff;
  z-index: 1000;
  box-shadow: 2px 2px 5px #999;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

/* .zoomBuscarMovil {
  transform: scale(0.6) !important;
  transition: transform 0.2s;
}

.zoomBuscarMovil:hover {
  transform: scale(1) !important;
} */

/* .lupaMovil {
  position: absolute;
  left: 100%;
  height: 105%;
  width: 17%;
  display: flex;
} */

.texto-AyudaFiltro {
  margin-top: 20%;
  margin-left: 20px;
  font-size: 20px;
}

.texto-IniciarSesionFiltro {
  margin-top: 20%;
  margin-right: 10px;
  font-size: 20px;
}

.carrito {
  position: absolute;
  left: 55%;
  top: 1%;
}

/* #ImagenBolsa {
  margin-left: 10px;
  margin-top: 5px;
  position: absolute;
  right: 1%;
  top: 1%;
  object-fit: contain;
  height: 35px;
  width: 37px;
} */

#Bolsa {
  position: absolute;
  height: 45px;
  width: 150px;
  margin-top: 17px;
  right: 1%;
  display: flex;
  font-size: 25px;
}

/* #CantidadCarrito {
  position: absolute;
  left: 70%;
  top: 15%;
  transform: translate(-67%, 0%);
  -webkit-transform: translate(-67%, 0%);
  font-weight: bold;
  margin-top: 10px;
  font-size: 15px;
} */

/* Mayoristas Seccion */
#ImagenCroattaMayoristas {
  height: 60px;
  width: 170px;
  object-fit: contain;
}

#espacioLogoMayoristas {
  position: absolute;
  top: 2px;
  left: 50%;
  transform: translate(-50%, 0%);
  -webkit-transform: translate(-50%, 0%);
}

#FiltrosMayoristas {
  height: 100vh;
  width: 100vw;
  background-color: rgba(255, 255, 255);
  z-index: 2000;
  transition: 1s;
  overflow-y: auto;
  overflow-x: hidden;
}

#MenuFiltrosMayoristas {
  margin-left: 10px;
  margin-top: 25px;
  width: 30px;
  height: 30px;
  object-fit: contain;
  background-repeat: no-repeat;
}

#botonCerrarMayoristas {
  position: absolute;
  top: 3%;
  right: 6%;
  font-size: 1.5em;
  z-index: 2500;
}

/*Menos de 1400px de ancho (Filtro Movil)*/
@media (max-width: 1055px) {
  #FiltrosWeb {
    display: none;
  }

  #Buscar {
    display: none;
  }

  /* #BuscarFiltro {
    position: absolute;
    left: 33%;
    top: 1%;
    transform: translate(-50%, -0%);
    -webkit-transform: translate(-50%, -0%);
    text-align: center;
    display: flex;
  } */

  #BarraFiltro {
    background-color: #d8d8d800;
    border: none;
    border-bottom: 1.8px solid;
    font-size: 21px;
    outline: none;

    padding: 0;
    width: 150px;
  }

  /* #espacioLogo {
    position: absolute;
    top: 5%;
    left: 50%;
    display: flex;
    transform: translate(-50%, 0%);
    -webkit-transform: translate(-50%, 0%);
    align-items: center;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
  } */

  /* #ImagenCroatta {
    height: 70px;
    width: 170px;
    object-fit: contain;
  } */

  .texto-Ayuda {
    display: none;
  }

  .texto-IniciarSesion {
    display: none;
  }

  .imagen-IniciarSesion {
    display: none !important;
  }

  #BotonInicioMenu {
    position: absolute;
    right: 0%;
    bottom: 0%;
  }
  div .cajaSesionMenu {
    position: absolute;
    top: -70px;
    left: -200px;
    width: 200px;
    height: 100px;
    background-color: #ffffff;
    z-index: 1000;
    box-shadow: 2px 2px 5px #999;
    margin-bottom: 0px;
    padding-bottom: 0px;
  }

  /* .zoomBuscarMovil {
    transform: scale(0.6) !important;
    transition: transform 0.2s;
  }

  .zoomBuscarMovil:hover {
    transform: scale(1) !important;
  } */

  /* .lupaMovil {
    position: absolute;
    left: 100%;
    height: 105%;
    width: 17%;
    display: flex;
  } */

  .texto-AyudaFiltro {
    margin-top: 20%;
    margin-left: 20px;
    font-size: 20px;
  }

  .texto-IniciarSesionFiltro {
    margin-top: 20%;
    margin-right: 10px;
    font-size: 20px;
  }

  .carrito {
    position: absolute;
    left: 55%;
    top: 1%;
  }

  /* #ImagenBolsa {
    margin-left: 10px;
    margin-top: 5px;
    position: absolute;
    right: 1%;
    top: 1%;
    object-fit: contain;
    height: 35px;
    width: 37px;
  } */

  #Bolsa {
    position: absolute;
    height: 45px;
    width: 150px;
    margin-top: 17px;
    right: 1%;
    display: flex;
    font-size: 25px;
  }

  /* #CantidadCarrito {
    position: absolute;
    left: 70%;
    top: 15%;
    transform: translate(-67%, 0%);
    -webkit-transform: translate(-67%, 0%);
    font-weight: bold;
    margin-top: 10px;
    font-size: 15px;
  } */

  /* Mayoristas Seccion */
  #ImagenCroattaMayoristas {
    height: 60px;
    width: 170px;
    object-fit: contain;
  }

  #espacioLogoMayoristas {
    position: absolute;
    top: 2px;
    left: 50%;
    transform: translate(-50%, 0%);
    -webkit-transform: translate(-50%, 0%);
  }

  #FiltrosMayoristas {
    height: 100vh;
    width: 100vw;
    background-color: rgba(255, 255, 255);
    z-index: 2000;
    transition: 1s;
    overflow-y: auto;
    overflow-x: hidden;
  }

  #MenuFiltrosMayoristas {
    margin-left: 10px;
    margin-top: 25px;
    width: 30px;
    height: 30px;
    object-fit: contain;
    background-repeat: no-repeat;
  }

  #botonCerrarMayoristas {
    position: absolute;
    top: 2%;
    right: 7%;
    font-size: 1.5em;
    z-index: 2500;
  }
}

#FiltrosWeb {
  display: none;
}

#Buscar {
  display: none;
}

/* #BuscarFiltro {
  position: absolute;
  left: 33%;
  top: 1%;
  transform: translate(-50%, -0%);
  -webkit-transform: translate(-50%, -0%);
  text-align: center;
  display: flex;
} */

tro {
  background-color: #d8d8d800;
  border: none;
  border-bottom: 1.8px solid;
  font-size: 21px;
  outline: none;

  padding: 0;
  width: 150px;
}

/* #espacioLogo {
  position: absolute;
  top: 5%;
  left: 50%;
  display: flex;
  transform: translate(-50%, 0%);
  -webkit-transform: translate(-50%, 0%);
  align-items: center;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
} */
/* 
#ImagenCroatta {
  height: 70px;
  width: 170px;
  object-fit: contain;
} */

.texto-Ayuda {
  display: none;
}

.texto-IniciarSesion {
  display: none;
}

.imagen-IniciarSesion {
  display: none !important;
}

#BotonInicioMenu {
  position: absolute;
  right: 0%;
  bottom: 0%;
}
div .cajaSesionMenu {
  position: absolute;
  top: -70px;
  left: -200px;
  width: 200px;
  height: 100px;
  background-color: #ffffff;
  z-index: 1000;
  box-shadow: 2px 2px 5px #999;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

/* .zoomBuscarMovil {
  transform: scale(0.6) !important;
  transition: transform 0.2s;
}

.zoomBuscarMovil:hover {
  transform: scale(1) !important;
} */

/* .lupaMovil {
  position: absolute;
  left: 100%;
  height: 105%;
  width: 17%;
  display: flex;
} */

.texto-AyudaFiltro {
  margin-top: 20%;
  margin-left: 20px;
  font-size: 20px;
}

.texto-IniciarSesionFiltro {
  margin-top: 20%;
  margin-right: 10px;
  font-size: 20px;
}

.carrito {
  position: absolute;
  left: 55%;
  top: 1%;
}

/* #ImagenBolsa {
  margin-left: 10px;
  margin-top: 5px;
  position: absolute;
  right: 1%;
  top: 1%;
  object-fit: contain;
  height: 35px;
  width: 37px;
} */

#Bolsa {
  position: absolute;
  height: 45px;
  width: 150px;
  margin-top: 17px;
  right: 1%;
  display: flex;
  font-size: 25px;
}

/* #CantidadCarrito {
  position: absolute;
  left: 70%;
  top: 15%;
  transform: translate(-67%, 0%);
  -webkit-transform: translate(-67%, 0%);
  font-weight: bold;
  margin-top: 10px;
  font-size: 15px;
} */

/* Mayoristas Seccion */
#ImagenCroattaMayoristas {
  height: 60px;
  width: 170px;
  object-fit: contain;
}

#espacioLogoMayoristas {
  position: absolute;
  top: 2px;
  left: 50%;
  transform: translate(-50%, 0%);
  -webkit-transform: translate(-50%, 0%);
}

#FiltrosMayoristas {
  height: 100vh;
  width: 100vw;
  background-color: rgba(255, 255, 255);
  z-index: 2000;
  transition: 1s;
  overflow-y: auto;
  overflow-x: hidden;
}

#MenuFiltrosMayoristas {
  margin-left: 10px;
  margin-top: 25px;
  width: 30px;
  height: 30px;
  object-fit: contain;
  background-repeat: no-repeat;
}

#botonCerrarMayoristas {
  position: absolute;
  top: 2%;
  right: 7%;
  font-size: 1.5em;
  z-index: 2500;
}

.SinDecoracion {
  /*Fondo y decoracion*/
  padding: 0;
  border: 0;
  background: none;
  text-decoration: none;
}

/*Derecha*/
#ZonaDerecha {
  position: absolute;
  top: 50%;
  right: 1%;
  width: 150px;
  transform: translate(-1%, -50%);
  display: flex;
}

#botonDerecha {
  margin-top: 7%;
  margin-right: 10px;
  height: 10%;
  width: 10%;
}

/*Izquierda*/
#ZonaIzquierda {
  position: absolute;
  top: 50%;
  left: 1%;
  width: 150px;
  transform: translate(-1%, -50%);
  display: flex;
  outline: none;
}

#ZonaIzquierda:hover #Minoristas,
#botonIzquierda {
  margin-right: 10px;
  margin-left: 10px;
}

#ZonaDerecha:hover #botonDerecha {
  margin-left: -10px;
}

#ZonaDerecha:hover #Mayoristas {
  margin-left: 15px;
}

#botonIzquierda {
  margin-top: 7%;
  margin-left: 10px;
  height: 10%;
  width: 10%;
}

/*---------------*/

.contenedor {
  position: relative;
  -ms-scroll-snap-type: y mandatory;
      scroll-snap-type: y mandatory;
  height: 100vh;
  overflow: auto;
}

#imagenCarousel {
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 0;
}

.snap {
  position: relative;
  scroll-snap-align: center;
}

.contenedor::-webkit-scrollbar {
  display: none;
}

.contenedor {
  scrollbar-width: none;
}

img {
  -webkit-select: none;
  -moz-select: none;
  -khtml-select: none;
  -ms-select: none;
}

span {
  -webkit-select: none;
  -moz-select: none;
  -khtml-select: none;
  -ms-select: none;
}

.marcasIndex {
  background: none;
  text-decoration: none;
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 4px;
  transition: background-size 0.3s;
  background-color: #ffffff00;
  outline: none;
  text-decoration: none;
  padding: 0;
  border: 0;
}

.marcasIndex:focus {
  outline: none;
  text-decoration: none;
  padding: 0;
  border: 0;
}

.Spinner {
  background-color: rgb(4 4 4 / 50%);
  width: 100vw;
  height: 100vh;
  z-index: 10000;
  top: 0;
  left: 0;
  position: absolute;
}

.posicionSpinner {
  position: absolute;
  left: 50%;
  top: 35%;
  transform: translate(-50%, -35%);
  -webkit-transform: translate(-50%, -35%);
}

.posicionTextoSpinner {
  position: absolute;
  left: 50%;
  top: 65%;
  transform: translate(-50%, -65%);
  -webkit-transform: translate(-50%, -65%);
}

@keyframes wave {
  0% {
    background-position: 0 160px;
    background-size: 170px 300px;
  }

  100% {
    background-position: 500px -18px;
    background-size: 250px 150px;
  }
}

.loader {
  width: 120px;
  height: 120px;
  position: fixed;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  text-align: center;
  line-height: 120px;
  font-family: sans-serif;
  color: #ffffff;
  font-size: 16px;
}

.loader span {
  z-index: 3;
}

.loader-bg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: repeat-x 0 100px/150px 300px rgb(54, 86, 134);
  z-index: 2;
  animation: wave 5s ease-out forwards;
}

/*Menos de 1400px de ancho(Tipo: Movil Normal)*/
@media (max-width: 1000px) {
  #imagenCarousel {
    position: relative;
    height: 100vh;
    z-index: 0;
  }
  #botonCentral {
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -45%);
    -webkit-transform: translate(-50%, -45%);
    object-fit: contain;
    color: white;
    border: 2px solid !important;
    font-size: 3.5em;
    height: 70px;
    line-height: 12%;
    padding-right: 20px;
    padding-left: 20px;
    text-align: center;
    z-index: 10;
  }

  #botonArriba {
    display: none;
  }

  #botonAbajo {
    display: none;
  }

  #MercadoWeb {
    display: none;
  }

  #Mayoristas {
    position: absolute;
    bottom: 15%;
    right: 15%;
    margin-left: 20px;
    font-size: 1.8em;
  }

  #Minoristas {
    position: absolute;
    bottom: 15%;
    left: 15%;
    font-size: 1.8em;
  }

  .indicadores {
    position: fixed;
    right: 3%;
    top: 41%;
    transform: translate(-3%, -41%);
    -webkit-transform: translate(-3%, -41%);
  }

  .radioMovil {
    margin-top: 20px;
  }

  #Buscanos {
    font-size: 2em;
    width: 210px;
    color: rgb(0, 0, 0);
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -40%);
    -webkit-transform: translate(-50%, -40%);
  }

  #BotonesRedes {
    margin-left: 10px;
    margin-right: 10px;
    height: 80%;
    width: 20%;
  }

  #ZonaCentral {
    position: absolute;
    top: 52%;
    left: 50%;
    width: 320px;
    height: 9%;
    transform: translate(-50%, -52%);
    display: flex;
  }
}

/*Mas de 1400px de ancho (Filtro Web)*/
@media (min-width: 1000px) {
  #botonCentral {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    object-fit: contain;
    color: white;
    border: 2px solid !important;
    font-size: 6em;
    height: 100px;
    line-height: 12%;
    padding-right: 20px;
    padding-left: 20px;
    text-align: center;
    z-index: 10;
  }

  #botonCentral:before {
    content: "";
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 0px;
    background: rgba(0, 0, 0, 0.582);
    transition: all 0.5s ease;
    z-index: -10;
  }

  #botonCentral:hover:before {
    height: 100%;
  }

  .BotonCentralMovil {
    display: none;
  }

  #botonArriba {
    position: absolute;
    left: 50%;
    top: 5%;
    transform: translate(-50%, -5%);
    -webkit-transform: translate(-50%, -5%);
    z-index: 1000;
    outline: none;
  }

  #botonAbajo {
    position: absolute;
    left: 50%;
    bottom: 5%;
    transform: translate(-50%, -5%);
    -webkit-transform: translate(-50%, -5%);

    outline: none;
  }

  #botonAbajo:hover {
    margin-bottom: 5px;
  }

  #botonArriba:hover {
    margin-top: 5px;
  }

  #Mayoristas {
    margin-right: 1%;
    font-size: 1.8em;
  }

  #Minoristas {
    margin-left: 10%;
    font-size: 1.8em;
  }

  #MercadoMovil {
    display: none;
  }

  .indicadores {
    position: fixed;
    right: 3%;
    bottom: 5%;
    transform: translate(-5%, -10%);
    -webkit-transform: translate(-5%, -10%);
  }

  #Buscanos {
    font-size: 5em;
    color: rgb(0, 0, 0);
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -40%);
    -webkit-transform: translate(-50%, -40%);
  }

  #BotonesRedes {
    margin-left: 10px;
    margin-right: 10px;
    height: 80%;
    width: 20%;
  }

  #ZonaCentral {
    position: absolute;
    top: 52%;
    left: 50%;
    height: 9%;
    transform: translate(-50%, -52%);
    display: flex;
  }
}

/* @media (min-width: 1000px) and (max-width: 1280px) {
  #imagenCarousel {
    position: relative;
    height: 110vh;
    width: max-content;
    z-index: 0;
  }
} */

@media (min-width: 1480px) {
  #imagenCarousel {
    position: relative;
    height: 100%;
    width: 100%;
    z-index: 0;
  }
}

.FuenteBebas {
  font-family: "Bebas";
}
.FuenteHelvetica {
  font-family: "Helvetica";
}

.FuentePoppinsB {
  font-family: "Poppins-Bold";
}

.FuentePoppinsL {
  font-family: "Poppins-Light";
  font-weight: 600;
}

.FuentePoppinsItalicB {
 font-family: "Poppins-SemiBoldItalic";
}

input[type="radio"]:after {
  width: 15px !important;
  height: 15px !important;
  border-radius: 15px !important;
  top: -2px !important;
  left: -1px !important;
  position: relative !important;
  background-color: #d1d3d1 !important;
  content: "" !important;
  display: inline-block !important;
  visibility: visible !important;
  border: 2px solid white !important;
}

input[type="radio"]:checked:after {
  width: 17px !important;
  height: 17px !important;
  border-radius: 15px !important;
  top: -2px !important;
  left: -1px !important;
  position: relative !important;
  background-color: #000000 !important;
  content: "" !important;
  display: inline-block !important;
  visibility: visible !important;
  border: 2px solid white !important;
}

#genero {
  text-decoration: none;
  outline: none;
  padding: 0;
  border: none;
  background: none;
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 4px;
  transition: background-size 0.3s;
  font-size: 1.2rem;
  color: black;
  background-color: #ffffff00;
}

#genero:hover,
#genero:focus {
  background-size: 100% 4px;
}

.categorias {
  outline: none;
  padding: 0;
  border: none;
  background: none;
  text-decoration: none;
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 4px;
  transition: background-size 0.3s;
  font-size: 1rem;
  color: black;
  background-color: #ffffff00;
}

.SinDecoracionFiltros {
  /*Fondo y decoracion*/
  padding: 0;
  border: 0;
  text-decoration: none;
  outline: none;
}

.SinDecoracionFiltros:focus {
  /*Fondo y decoracion*/
  padding: 0;
  border: 0;
  text-decoration: none;
  outline: none;
}

.marcas {
  outline: none;
  padding: 0;
  border: none;
  background: none;
  text-decoration: none;
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 4px;
  transition: background-size 0.3s;
  font-size: 1rem;
  color: black;
  background-color: #ffffff00;
  outline: none;
  text-decoration: none;
  padding: 0;
  border: 0;
}

.marcas:focus {
  outline: none;
  text-decoration: none;
  padding: 0;
  border: 0;
}

#tallas {
  text-decoration: none;
  font-size: 20px;
  color: black;
  margin: 0;
}

.radio-filtros input[type="radio"] {
  margin-top: 10px;
  border-radius: 15px;
  width: 15px;
  height: 15px;
  content: "";
}

.radio-filtros input[type="radio"]:after {
  margin-top: 10px;
  border-radius: 17px;
  position: relative;
  width: 16px;
  height: 16px;
  content: " ";
  background-color: #ffffff00;
  display: inline-block;
}

.radio-filtros input[type="radio"]:checked:after {
  margin-top: -1px;
  margin-left: -1px;
  border-radius: 17px;
  position: relative;
  width: 17px;
  height: 17px;
  content: " ";
  background-color: #000000;
  display: inline-block;
}

.boton_personalizado {
  height: 30px;
  width: 30px;
  border-radius: 20px;
}

.botonesTallas {
  width: 100%;
}

.bola {
  height: 30px;
  width: 30px;
  cursor: pointer;
  background-repeat: no-repeat;
  margin: 5px;
}

.flechaFiltro {
  position: relative;
  top: 9px;
  left: 10px;
  height: 26px;
  width: 15px;
  display: flex;
}

.botonLimpiarFiltrosF {
  /* height: 2rem; */
  padding: 10px !important;
  /* margin-top: 20px !important; */
  /* margin-left: 40px !important; */
  /* margin-right: 40px !important; */
  color: #000000 !important;
  background-color: #dcdcdc;
  font-size: 1rem !important;
  border: solid 1px transparent !important;
}

.botonLimpiarFiltrosF:hover {
  color: #ffffff !important;
  background-color: #000000;
  border: solid 3px #000000;
}

.lineaFiltros {
  color: #000000;
  background-color: #000000;
  /* height: 1px; */
  border-color: #000000;
  margin-bottom: 0px;
  margin-top: 20px;
  margin-left: 1.8em;
  margin-right: 1.8em;
}

.filaFiltros {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 10%;
}

.filaNew {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 10%;
  margin-bottom: 5px;
}

.filaGenero {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  align-items: center;
  margin-left: 10%;
  margin-bottom: 5px;
}

.filaMarca {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 10%;
  margin-bottom: 5px;
}

.columnasMarcas {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 10%;
}

.columnasCategorias {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 10%;
}

.filaTallas {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-left: 7%;
  margin-bottom: 5px;
}

.filaColores {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-left: 7%;
  margin-bottom: 5px;
}

.strikeNegro {
  position: relative;
  display: inline-block;
}

.strikeNegro:before {
  content: "";
  position: absolute;
  height: 3px;
  width: 100%;
  background: black;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(25deg);
}

.strikeBlanco {
  position: relative;
  display: inline-block;
}

.strikeBlanco:before {
  content: "";
  position: absolute;
  height: 3px;
  width: 100%;
  background: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(25deg);
}

.form-check-label {
  margin-top: 0 !important;
}

#tallas {
  position: relative;
  top: 0px;
  text-align: center;
}

#talla {
  font-size: 20px;
}

.imgTalla {
  height: 1.8em;
  width: 1.8em;
  border-radius: 50%;
  margin-right: 10px;
}

#Linea {
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 28px 30px;
  background-repeat: no-repeat;
  background-size: 100% 4px;
  color: black;
}

.SinDecoracion {
  /*Fondo y decoracion*/
  padding: 0;
  border: 0;
  background: none;
  text-decoration: none;
  outline: none;
}

.SinDecoracion:focus {
  /*Fondo y decoracion*/
  padding: 0;
  border: 0;
  background: none;
  text-decoration: none;
  outline: none;
}

.FuenteBebas {
  font-family: "Bebas";
}

.FuenteHelvetica {
  font-family: "Helvetica";
}

.NoHayMas {
  margin-left: 30px;
  margin-top: 5px;
}

.btn {
  margin: 15px;
  padding: 5px;
}

[type="radio"] {
  margin: 0;
  padding: 0;
  -webkit-appearance: none;
  appearance: none;
}

select {
  appearance: none;
  -webkit-appearance: none;
  background-color: transparent;
  border: 0;
  padding: 10px;
  margin: -5px -20px -5px -5px;
}

#contentedor select option {
  display: inline-block;
}

#contentedor {
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
}

.btnCantidad {
  font-size: 0.6rem;
  line-height: 15px;
  background: black;
  color: white;
  padding: 6px !important;
}

@media (max-width: 480px) {
  .imgTalla {
    height: 1.3em;
    width: 1.3em;
    border-radius: 50%;
    margin-right: 2px;
  }

  .btnCantidad {
    font-size: 0.6rem;
    line-height: 15px;
    background: black;
    color: white;
    padding: 0.5px !important;
  }
}

.MercadoMayoristas {
  height: 85vh;
  width: 100%;
  background-color: white;
  position: absolute;
  top: 10%;
  overflow-y: auto;
  overflow-x: hidden;
}

.mensajeAnuncio {
  margin-bottom: 150px;
  font-weight: bold;
  font-size: 15px;
  width: 200px;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0%);
}

@font-face {
  font-family: "HelveticaBold";
  src: url(/static/media/HelveticaBold.f479116c.otf);
  font-display: swap;
}

.FuenteHelveticaBold {
  font-family: "HelveticaBold";
}

.imagenCategoria {
  width: 95px;
  height: 95px;
  margin-right: 20px;
}

.Bar {
  margin-top: 20px;
  width: 85vw;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0%);
}

.barLinea {
  display: flex;
    flex-direction: row;
    justify-content: center;
    cursor: pointer;
    align-items: center;
}

.Descuento {
  color: red;
  font-size: 1.2rem;
  font-family: "HelveticaBold";
  position: absolute;
  right: 2%;
  top: 40%;
}

div.vertical-line {
  width: 1px;
  background-color: black;
  height: 95px;
  float: left;
  z-index: 100;
  margin-right: 20px;
}

.LineaBar {
  color: #000000;
  background-color: #000000;
  height: 1;
  border-color: #000000;
  margin-bottom: 0px;
  margin-top: 1.5em;
  border: solid 2px #e9ecef
}

.flechaBar {
  margin-top: 3px;
  width: 1.2rem;
  margin-left: 15px
}

.productosDespliegue {
  transition: all 200ms ease-out;
}

.flechaGrandeAbajo {
  position: absolute;
  left: 50%;
  margin-top: 0px;
  transform: translate(-50%, 0%);
  animation: movement 1.5s linear infinite;
}

.newProduct {
  color: blue;
  position: absolute;
  transform: translate(-25%, 30px);
  font-weight: bold;

}

@keyframes movement {
  0% {
    margin-top: 0px;
  }
  50% {
    margin-top: 20px;
  }
  100% {
    margin-top: 0px;
  }
}

.dropdown-slidedown {
  max-height: 100%;
  transition-duration: 0.8s;
  transition-timing-function: ease-in-out;
}

#noHayWebMayoristas {
  margin-top: 30px;
  margin-bottom: 30px;
}

#noHayMovilMayoristas {
  display: none;
}

.cuentaRegresiva {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

#circle span {
  position: fixed;
  width: 10px;
  height: 40px;
  bottom: 49px;
  right: 40px;
  text-align: center;
  font-size: 11px;
  font-family: Arial, Helvetica, sans-serif;
  color: #000;
  transform-origin: bottom center;
}

.float {
  position: fixed;
  width: 51px;
  height: 51px;
  bottom: 24px;
  right: 19px;
  background-color: #25d366;
  color: #fff;
  border-radius: 33px;
  text-align: center;
  font-size: 30px;
  z-index: 1000;
}
.float:hover {
  text-decoration: none;
  color: #25d366;
  background-color: #fff;
}

.my-float {
  margin-top: 11px;
}

/* ESTILOS NORMALES (para escritorios muy grandes)
   ========================================================================= */

#filaMayorita {
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  padding: 1rem;
  display: grid;
  grid-gap: 1rem;
  grid-gap: 5rem;
  gap: 5rem;
  grid-auto-rows: auto;
  grid-template-columns: repeat(auto-fill, minmax(17rem, 1fr));
  justify-content: center;
  align-items: center;
  justify-items: center;
  align-content: center;
}
/* RESPONSIVE
   ========================================================================= /
 
/ Tablets en horizonal y escritorios normales
   ------------------------------------------------------------------------- */
@media (min-width: 768px) and (max-width: 1199px) {
  #filaMayorita {
    width: 100%;
    padding-top: 1rem;
    padding-left: 5%;
    padding-right: 5%;
    display: grid;
    grid-gap: 1rem;
    grid-gap: 2rem;
    gap: 2rem;
    grid-auto-rows: auto;
    grid-template-columns: repeat(auto-fill, minmax(14rem, 1fr));
    justify-content: center;
    align-items: center;
    justify-items: center;
    align-content: center;
  }
}

/* Móviles en horizontal o tablets en vertical
   ------------------------------------------------------------------------- */
@media (max-width: 767px) {
  #filaMayorita {
    width: 100%;
    padding: 1rem;
    display: grid;
    grid-gap: 1rem;
    grid-gap: 4rem;
    gap: 4rem;
    grid-auto-rows: auto;
    grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
    justify-content: center;
    align-items: center;
    justify-items: center;
    align-content: center;
  }
}

/* Móviles en vertical
   ------------------------------------------------------------------------- */
@media (max-width: 480px) {
  #filaMayorita {
    width: 100%;
    padding: 1rem;
    display: grid;
    grid-gap: 1rem;
    grid-gap: 2rem;
    gap: 2rem;
    grid-auto-rows: auto;
    grid-template-columns: repeat(auto-fill, minmax(11rem, 1fr));
    justify-content: center;
    align-items: center;
    justify-items: center;
    align-content: center;
  }
}

/*Mas de 800px de ancho y menos de 1150px de ancho (Tipo: Pc, Web)*/
@media (min-width: 800px) and (max-width: 1055px) {
  .listaProductos {
    width: 85vw;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .listaProductos .img-producto {
    width: 36.5vw;
    height: 500px;
    object-fit: contain;
  }

  .listaProductos .carta {
    width: 36.5vw;
    height: 40vh;
    margin: 1vw;
  }

  .listaProductos .imgMedidas {
    width: 36.5vw;
    margin-bottom: 10px;
  }

  .listaProductos #noHayWebM {
    width: 85vw;
    height: 80vh;
  }

  .listaProductos #noHayMovilM {
    display: none;
  }

  .listaProductos .ImgNoHayWebM {
    width: 120%;
    height: 90%;
  }

  .listaProductos #WebGrande {
    display: none;
  }

  .listaProductos .tipoCompra img {
    height: 25px;
    width: 25px;
    margin: 5px;
  }

  .listaProductos .tipoCompra p {
    width: 50px;
    height: 30px;
    font-size: 12px;
    margin: 0px 5px 0px 5px;
  }

  .categoriaBar {
    font-size: 1.6rem;
    margin-bottom: 0px
  }

  .relojContador {
    max-width: 500px;
    font-weight: bold;
    font-size: 20px;
    position: relative;
    left: 50%;
    transform: translate(-50%, 0%);
    margin-bottom: 20px;
  }

  .cuentaRegresiva {
    font-size: 30px;
    border: 2px solid;
    background-color: red;
  }

  .numeroReloj {
    color: black;
    background-color: white;
    padding: 5px 15px 5px 15px;
  }

  .contadorUnitario {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px;
    color: white;
  }

  .textContadorUnitario {
    font-size: 20px;
  }
}

/*Mas de 600px de ancho y mas de 850px de alto (Tipo: Pc, Web)*/
@media (min-width: 1055px) {
  .listaProductos {
    width: 85vw;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .listaProductos .img-producto {
    width: 26vw;
    height: 500px;
    object-fit: contain;
  }

  .listaProductos .carta {
    width: 26vw;
    height: 40vh;
    margin: 1vw;
  }

  .listaProductos .imgMedidas {
    width: 25vw;
    margin-bottom: 20px;
  }

  .listaProductos #noHayWebM {
    width: 85vw;
    height: 80vh;
  }

  .listaProductos .ImgNoHayWebM {
    width: 85vw;
    height: 80vh;
    object-fit: contain;
  }

  .listaProductos #noHayMovilM {
    display: none;
  }
  .listaProductos #WebPequeña {
    display: none;
  }

  .listaProductos .tipoCompra img {
    height: 25px;
    width: 25px;
    margin: 5px;
  }

  .listaProductos .tipoCompra p {
    width: 50px;
    height: 30px;
    font-size: 12px;
    margin: 0px 5px 0px 5px;
  }

  .categoriaBar {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px;
    margin-bottom: 0px
  }

  .relojContador {
    max-width: 500px;
    font-weight: bold;
    font-size: 20px;
    position: relative;
    left: 50%;
    transform: translate(-50%, 0%);
    margin-bottom: 20px;
  }

  .cuentaRegresiva {
    font-size: 30px;
    border: 2px solid;
    background-color: red;
  }

  .numeroReloj {
    color: black;
    background-color: white;
    padding: 5px 15px 5px 15px;
  }

  .contadorUnitario {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px;
    color: white;
  }

  .textContadorUnitario {
    font-size: 20px;
  }
}

/*Mas de 800px de ancho y menos de 1150px de ancho (Tipo: Pc, Web)*/
@media (max-width: 800px) and (min-width: 320px) {
  .listaProductos {
    width: 85vw;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .listaProductos .img-producto {
    width: 83vw;
    height: 70vh;
    object-fit: contain;
  }

  .listaProductos .carta {
    width: 83vw;
    height: 40vh;
    margin: 1vw;
  }

  .listaProductos .imgMedidas {
    width: 83vw;
    margin-bottom: 10px;
  }

  .listaProductos #noHayWebM {
    display: none;
  }

  .listaProductos #noHayMovilM {
    width: 85vw;
    margin-top: 10px;
  }

  .listaProductos .ImgNoHayMovilM {
    width: 85vw;
    object-fit: contain;
  }

  .listaProductos .ImgNoHayWebM {
    display: none;
  }

  .listaProductos #WebGrande {
    display: none;
  }

  .listaProductos .tipoCompra img {
    height: 25px;
    width: 25px;
    margin: 5px;
  }

  .listaProductos .tipoCompra p {
    width: 50px;
    height: 30px;
    font-size: 12px;
    margin: 0px 5px 0px 5px;
  }

  .categoriaBar {
    font-size: 24px;
    margin-top: auto;
    margin-bottom: 0;
  }

  .LineaBar {
    margin-top: 20px;
    margin-top: 20px;
  }

  .flechaBars {
    position: absolute;
    right: -1%;
    top: 60px;
    transform: translate(-1%, -50%);
    height: 15px;
  }

  .relojContador {
    font-weight: bold;
    font-size: 20px;
    position: relative;
    left: 50%;
    transform: translate(-50%, 0%);
    margin-bottom: 20px;
  }

  .cuentaRegresiva {
    font-size: 20px;
  }

  .numeroReloj {
    color: black;
    background-color: white;
    padding: 5px 15px 5px 15px;
  }

  .contadorUnitario {
    background-color: red;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    color: white;
  }

  .textContadorUnitario {
    font-size: 15px;
  }

}

/* ESTILOS NORMALES (para escritorios muy grandes)
   ========================================================================= */

#fila {
  width: 100%;
  position: relative;
  padding: 3%;
  padding-top: 1%;
  display: grid;
  grid-gap: 1rem;
  grid-gap: 3rem;
  gap: 3rem;
  grid-auto-rows: auto;
  grid-template-columns: repeat(auto-fill, minmax(25rem, 1fr));
  justify-content: center;
  align-items: center;
  justify-items: center;
  align-content: center;
}
/* RESPONSIVE
   ========================================================================= /
 
/ Tablets en horizonal y escritorios normales
   ------------------------------------------------------------------------- */
@media (min-width: 1441px) and (max-width: 1920px) {
  #fila {
    grid-gap: 3rem;
    gap: 3rem;
    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  }
  .Categoria {
    font-size: 17px;
    margin-bottom: 5px;
  }
}

/* Móviles en horizontal o tablets en vertical
   ------------------------------------------------------------------------- */
@media (min-width: 767px) and (max-width: 1440px) {
  #fila {
    grid-gap: 4rem;
    gap: 4rem;
    grid-auto-rows: auto;
    grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
  }
  .Categoria {
    font-size: 15px;
    margin-bottom: 5px;
  }
}

/* Móviles en vertical
   ------------------------------------------------------------------------- */
@media (max-width: 480px) {
  #fila {
    grid-gap: 2rem;
    gap: 2rem;
    grid-auto-rows: auto;
    grid-template-columns: repeat(auto-fill, minmax(8rem, 1fr));
  }
  .Categoria {
    font-size: 12px;
    margin-bottom: 5px;
  }
}

/* @media (max-width: 1400px) {
  #fila {
    width: 100%;
    position: absolute;
    padding: 1rem;
    display: grid;
    grid-gap: 1rem;
    grid-gap: 1rem;
    gap: 1rem;
    grid-auto-rows: 40rem;
    grid-template-columns: repeat(auto-fill, minmax(17rem, 1fr));
    justify-content: center;
    align-items: center;
    justify-items: center;
    align-content: center;
  }
}
@media (max-width: 1167px) {
  #fila {
    width: 100%;
    position: absolute;
    padding: 1rem;
    display: grid;
    grid-gap: 1rem;
    grid-gap: 1rem;
    gap: 1rem;
    grid-auto-rows: 40rem;
    grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
    justify-content: center;
    align-items: center;
    justify-items: center;
    align-content: center;
  }
}

#fila {
  width: 100%;
  position: absolute;
  padding: 1rem;
  display: grid;
  grid-gap: 1rem;
  gap: 1rem;
  grid-auto-rows: 31rem;
  grid-template-columns: repeat(auto-fill, minmax(8rem, 1fr));
  justify-content: center;
  align-items: center;
  justify-items: center;
  align-content: center;
} */

.centerBlock {
  display: table;
  margin: auto;
}

.FuenteBebas {
  font-family: "Bebas";
}

.FuenteHelvetica {
  font-family: "Helvetica";
}

.sinMargenes {
  margin: 0;
  padding: 0;
}

.Categoria {
  font-size: 20px;
  margin-bottom: 5px;
}

.MercadoMinoristas {
  height: 100%;
  width: 100%;
  background-color: white;
  position: absolute;
  top: 9%;
  overflow-y: auto;
  overflow-x: hidden;
}

.tipoCompra {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.objetoTipoCompra {
  display: flex;
  flex-direction: row;
  align-items: center;
}

div.vertical-line-producto {
  width: 2px;
  background-color: black;
  height: 30px;
  float: left;
  z-index: 100;
  margin: 0px;
}

.headerAcordeon {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.giroFlechaArriba {
  animation: 0.5s rotateArriba linear forwards;
  transform-origin: 50% 50%;
}

.giroFlechaAbajo {
  animation: 0.5s rotateAbajo linear;
  transform-origin: 50% 50%;
}

@keyframes rotateArriba {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(540deg);
  }
}

@keyframes rotateAbajo {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.flechaAcordeon {
  height: 10px;
  width: 20px;
  margin-bottom: 0px;
  margin-top: 0px;
}

#circle span {
  position: fixed;
  width: 10px;
  height: 40px;
  bottom: 49px;
  right: 40px;
  text-align: center;
  font-size: 11px;
  font-family: Arial, Helvetica, sans-serif;
  color: #000;
  transform-origin: bottom center;
}

.float {
  position: fixed;
  width: 51px;
  height: 51px;
  bottom: 24px;
  right: 19px;
  background-color: #25d366;
  color: #fff;
  border-radius: 33px;
  text-align: center;
  font-size: 30px;
  z-index: 1000;
}
.float:hover {
  text-decoration: none;
  color: #25d366;
  background-color: #fff;
}

.my-float {
  margin-top: 11px;
}

/*Menos de 600px de ancho y mas de 340 de alto(Tipo: Movil Normal)*/
@media (max-width: 800px) and (min-width: 340px) {
  /* #productos {
    width: 90vw;
    height: auto;
    top: 10%;
    left: 5%;
    overflow-y: auto;
    overflow-x: hidden;

  } */

  .img-producto {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .carta {
    width: 98vw;
    height: 40vh;
    margin: 1vw;
  }

  /* .imgMedidas {
    width: 95vw;
    margin-bottom: 10px;
  } */

  #noHayMovil {
    width: 90vw;
    height: 76vh;
    position: absolute;
    margin-top: 15px;
  }

  #noHayWeb {
    display: none;
  }

  .ImgNoHayMovil {
    width: 95%;
    height: 90%;
  }

  #WebGrande {
    display: none;
  }

  .tipoCompra p {
    width: 50px;
    height: 30px;
    font-size: 12px;
    margin: 0px 5px 0px 5px;
  }
}

/*Menos de 500px de alto(Tipo: Movil Normal Horizontal)*/
@media (max-height: 500px) {
  /* #productos {
    width: 90vw;
    height: auto;
    top: 10%;
    left: 5%;
    overflow-y: auto;
    overflow-x: hidden;

  } */

  .img-producto {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .carta {
    width: 95vw;
    height: 40vh;
    margin-left: 7%;
  }
  /* 
  .imgMedidas {
    width: 95vw;
    margin-bottom: 10px;
  } */

  #noHay {
    width: 70vw;
    height: 80vh;
    position: absolute;
    left: 7%;
  }

  #noHayMovil {
    display: none;
  }

  .ImgNoHayWeb {
    width: 90%;
    height: 90%;
  }

  .tipoCompra p {
    width: 50px;
    height: 30px;
    font-size: 12px;
    margin: 0px 5px 0px 5px;
  }
}

/*Menos de 600px de alto y menos de 340px de ancho (Tipo: Movil Pequeño)*/
@media (max-width: 340px) {
  /* #productos {
    width: 90vw;
    height: auto;
    top: 10%;
    left: 5%;
    overflow-y: auto;
    overflow-x: hidden;

  } */

  .img-producto {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .carta {
    width: 98vw;
    height: 40vh;
    margin: 1vw;
  }

  /* .imgMedidas {
    width: 98vw;
    margin-bottom: 10px;
  } */

  div.vertical-line-producto {
    width: 2px;
    background-color: black;
    height: 30px;
    float: left;
    z-index: 100;
    margin: 5px;
  }

  #noHayMovil {
    position: absolute;
    margin-top: 15px;
    padding-right: 20px;
  }

  #noHayWeb {
    display: none;
  }

  .ImgNoHayMovil {
    margin-top: 20px;
    width: 98%;
    height: 100%;
  }

  #WebGrande {
    display: none;
  }

  .tipoCompra p {
    width: 50px;
    height: 30px;
    font-size: 12px;
    margin: 0px 5px 0px 5px;
  }
}

/*Mas de 800px de ancho y menos de 1150px de ancho (Tipo: Pc, Web)*/
.imgMedidas {
  width: 100%;
  margin-bottom: 10px;
}
#productos {
  width: 90vw;
  height: auto;
  top: 15%;
  left: 5%;
  overflow-y: auto;
  overflow-x: hidden;
  /* padding-left: 3%; */
}
@media (min-width: 800px) and (max-width: 1055px) {
  /* #productos {
    width: 90vw;
    height: auto;
    top: 10%;
    left: 5%;
    overflow-y: auto;
    overflow-x: hidden;

  } */

  .img-producto {
    width: 100%;
    height: 100%;

    object-fit: cover;
  }

  .carta {
    width: 45vw;
    height: 40vh;
    margin-left: 15px;
    padding-left: 15px;
  }
  /* 
  .imgMedidas {
    width: 45vw;
    margin-bottom: 10px;
  } */

  #noHayWeb {
    width: 70vw;
    height: 80vh;
    position: absolute;
    left: 7%;
  }
  #noHayMovil {
    display: none;
  }

  .ImgNoHayWeb {
    position: relative;
    width: 120%;
    height: 90%;
  }

  #WebGrande {
    display: none;
  }

  .tipoCompra p {
    width: 50px;
    height: 30px;
    font-size: 12px;
    margin: 0px 5px 0px 5px;
  }
}

/*Mas de 600px de ancho y mas de 850px de alto (Tipo: Pc, Web)*/
@media (min-width: 1055px) {
  .img-producto {
    width: 100%;
    height: 100%;

    object-fit: contain;
  }

  .carta {
    width: 24.3vw;
    height: 40vh;
    margin: 1vw;
  }

  /* .imgMedidas {
    width: 24.3vw;
    margin-bottom: 20px;
  } */

  #noHayWeb {
    width: 70vw;
    height: 80vh;
    position: absolute;
    left: 7%;
  }

  #noHayMovil {
    display: none;
  }

  #filtros {
    position: fixed;
    top: 12%;
    left: -1%;
    width: 18vw;
    height: 88%;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .ImgNoHayWeb {
    position: relative;
    width: 100%;
    height: 90%;
  }

  #WebPequeña {
    display: none;
  }

  .tipoCompra p {
    width: 50px;
    height: 30px;
    font-size: 12px;
    margin: 0px 5px 0px 5px;
  }
}

/* Estilos para motores Webkit y blink (Chrome, Safari, Opera... )*/

*::-webkit-scrollbar {
  -webkit-appearance: none !important;
}

*::-webkit-scrollbar:vertical {
  width: 1vw !important;
}

*::-webkit-scrollbar-button:increment,
*::-webkit-scrollbar-button {
  display: none !important;
}

*::-webkit-scrollbar:horizontal {
  height: 10px !important;
}

/* *::-webkit-scrollbar-thumb {
  background-color: #a7a7a7 !important;
  border-radius: 20px !important;
  border: 2px solid #f1f2f3 !important;
} */

*::-webkit-scrollbar-track {
  border-radius: 10px !important;
}

@font-face {
  font-family: 'Arial_Bold';
  src: url(/static/media/Arial_Bold.12d05860.ttf) format('truetype'),
}

@font-face {
  font-family: 'Poppins-Light';
  src: url(/static/media/Poppins-Light.7641a0f7.ttf) format('truetype'),
}

@font-face {
  font-family: 'Poppins-Bold';
  src: url(/static/media/Poppins-Bold.cdb29a5d.ttf) format('truetype'),
}

@font-face {
  font-family: 'Poppins-SemiBoldItalic';
  src: url(/static/media/Poppins-SemiBoldItalic.3524ffc3.ttf) format('truetype'),
}

@font-face {
  font-family: 'CenturyGothic';
  src: url(/static/media/CenturyGothic.b1b792ba.ttf) format('truetype'),
}

.img-Modal {
  object-fit: contain;
  width: 20%;
  height: 20%;
  margin-top: 20px;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0%);
  -webkit-transform: translate(-50%, 0%);
}

#productosCarro {
  position: absolute;
  overflow: auto;
  height: 220px;
  overflow-x: hidden;
}

#texto-Error {
  margin-top: 50px;
  width: 100%;
  font-weight: bold;
}

#texto-Error-agotado {
  margin-top: 50px;
  width: 100%;
  font-weight: bold;
  font-size: 2em;
}

#TituloError {
  font-size: 3em;
}

.botonCerrarModal {
  padding-top: 5px !important;
  margin-top: 50px !important;
  color: #000000 !important;
  font-size: 25px !important;
  border: solid 3px;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0%);
  -webkit-transform: translate(-50%, 0%);
}

.botonCerrarModal:hover {
  color: #ffffff !important;
  background-color: #000000;
  border: solid 3px #000000;
}

.botonWhatsapp:hover {
  color: #ffffff !important;
  background-color: #000000;
  border: solid 3px #000000;
}
.botonWhatsapp {
  color: #000000 !important;
}

.list-group {
  max-height: 300px;
  margin-bottom: 10px;
  overflow: auto;
  -webkit-overflow-scrolling: auto;
}

.noPadding {
  padding: 0 !important;
}

.noMargen {
  margin: 0 !important;
}

.LineaTotal {
  color: #000000;
  background-color: #000000;
  height: 1px;
  border-color: #000000;
  width: 100%;
  padding: 0px !important;
  margin: 0px !important;
}

.atributosTabla {
  font-weight: bold;
  margin-top: 50px;
}

.img-producto-pedido {
  width: 100px;
  height: 150px;
  margin-left: 0px;
  margin-right: 10px;
  padding-right: 0px;
}

.centrar {
  display: table;
  margin: auto;
}

.FuenteBebas {
  font-family: "Bebas";
}

.FuenteHelvetica {
  font-family: "Helvetica";
}


.ayudaWeb {
  width: 100%;
  margin-top: 15%;
  margin-bottom: 10%;
}

@media (max-width: 767px) {
  .ayudaWeb {
    width: 100%;
    margin-top: 25%;
    margin-bottom: 20%;
  }
}
@media (max-width: 1400px) {
  .tituloAyuda {
    font-size: 30px;
  }

  .tituloOpcion {
    font-size: 20px;
  }

  .imagenAyuda {
    margin: 10px;
    object-fit: contain;
    width: 50px;
    height: 50px;
  }

  .imagenAlgodon {
    width: 80px;
    height: 80px;
  }

  .distanciaAyuda {
    margin: 10px;
  }

  .volver {
    position: absolute;
    top: 15vh;
    left: 5%;
    font-size: 20px;
  }

  .botonIzquierda {
    margin-left: 5px;
    margin-top: 6px;
    height: 20px;
    width: 10px;
  }

  .imagenTransportadoras {
    margin: 10px;
    height: 40px;
    width: 40px;
    object-fit: contain;
  }

  .imagenTransportadorasLargas {
    margin: 10px;
    height: 40px;
    width: 70px;
    object-fit: contain;
  }

  .textoFooter {
    display: none;
  }

  .textoAyuda {
    width: 300px;
    font-size: 15px;
  }
}

/*Mas de 1400px de ancho (Filtro Web)*/
@media (min-width: 1400px) {
  .tituloAyuda {
    font-size: 50px;
  }

  .tituloOpcion {
    font-size: 30px;
  }

  .imagenAyuda {
    margin: 50px;
  }

  .lineaAyuda {
    display: flex;
  }

  .imagenAlgodon {
    width: 100px;
    height: 100px;
  }

  .distanciaAyuda {
    margin: 50px;
  }

  .volver {
    position: absolute;
    top: 50%;
    left: 3%;
    font-size: 30px;
  }

  .botonIzquierda {
    margin-left: 10px;
    margin-top: 8px;
    height: 30px;
    width: 20px;
  }

  .imagenTransportadoras {
    margin: 10px;
    height: 100px;
    width: 100px;
    object-fit: contain;
  }

  .imagenTransportadorasLargas {
    margin: 10px;
    height: 100px;
    width: 200px;
    object-fit: contain;
  }

  .textoFooter {
    font-size: 15px;
    position: absolute;
    bottom: 0%;
  }

  .textoAyuda {
    width: 800px;
    font-size: 20px;
  }
}

#LineaPedido {
  color: #000000;
  background-color: #000000;
  height: 1px;
  border-color: #000000;
  width: 100%;
  margin: 0 !important;
  padding: 0 !important;
}

.noPadding {
  padding: 0 !important;
}

.noMargen {
  margin: 0 !important;
}

.LineaTotal {
  color: #000000;
  background-color: #000000;
  height: 1px;
  border-color: #000000;
  width: 100%;
  padding: 0px !important;
  margin: 0px !important;
}

.atributosTabla {
  font-weight: bold;
  margin-top: 50px;
}

.img-producto-pedido {
  width: 100px;
  height: 150px;
  margin-left: 0px;
  margin-right: 10px;
  padding-right: 0px;
}

.centrar {
  display: table;
  margin: auto;
}

.FuenteBebas {
  font-family: "Bebas";
}

.FuenteHelvetica {
  font-family: "Helvetica";
}

.SinDecoracionPedido {
  /*Fondo y decoracion*/
  padding: 0;
  border: 0;
  background: none;
  text-decoration: none;
  outline: none;
}

/* .botonPaso2 {
  color: #000 !important;
  background-color: #fff;
} */
.botonCupon {
  color: #000 !important;
  background-color: #fff;
  padding: 5px;
  max-width: 100px;
  max-height: 40px;
  border: solid 3px #000000;
  margin-left: 10px;
}

.botonCupon:hover {
  color: #ffffff !important;
  background-color: #000000;
  border: solid 3px #000000;
}

.botonPaso1:hover {
  color: #ffffff !important;
  background-color: #000000;
  border: solid 3px #000000;
}

.botonPaso2:hover {
  color: #ffffff !important;
  background-color: #000000;
  border: solid 3px #000000;
}

.botonPaso3:hover {
  color: #ffffff !important;
  background-color: #000000;
  border: solid 3px #000000;
}

.Efecty:hover {
  color: #ffffff !important;
  background-color: #000000;
  border: solid 3px #000000;
}

th {
  position: sticky;
  top: 0; /* Don't forget this, required for the stickiness */
  background-color: white !important;
}

#imgEslogan {
  position: absolute;
  bottom: 2.5%;
  left: 1%;
  height: 20px;
}

@font-face {
  font-family: "Bebas";
  src: url(/static/media/BEBASKAI.af73723c.OTF);
  font-display: swap;
}

@font-face {
  font-family: "Helvetica";
  src: url(/static/media/HELVETICALTSTD-LIGHT.14c4e0be.OTF);
  font-display: swap;
}

/*---------------------------------------------------------------------------------------------------------------------*/

form .form-info {
  padding: 10px 37px;
  margin: 5px 0;
}

.formulario select {
  margin-bottom: 30px;
}

.form-info input[type="text"],
[type="email"],
[type="date"],
[type="password"],
select,
textarea {
  border: none;
  border-bottom: solid 2px #474544;
  color: #474544;
  font-size: 0.8em;
  font-weight: 400;
  letter-spacing: 1px;
  margin-bottom: 30px;
  width: 100%;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  transition: all 0.3s;
}

.capitalizado {
  text-transform: capitalize;
}
input[type="text"]:focus,
[type="password"]:focus,
[type="email"]:focus,
textarea:focus {
  outline: none;
  padding: 0 0 0.875em 0;
}

#cupon_input {
  width: 200px;
}

.error {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAATCAMAAABIkLa5AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAANlBMVEUAAABqFB1qFB1qFB1qFB1qFB1qFB1qFB1qFB1qFB1qFB1qFB1qFB1qFB1qFB1qFB1qFB3///9SQFAtAAAAEHRSTlMAQCBg8MAQUODQgDBwoJCwOHiqygAAAAFiS0dEEeK1PboAAAAHdElNRQflAwEWFAqjkdytAAAAlklEQVQY02WQ6wLFEAyDi7rNTN7/aadu45z8E5p8RbSkNP3JMGDdj+mBAMTTdAEXpYj7cC9YSQbvGZrRqjKezS1yuvW67UwyaaSrJ3VFGKGwrdVP1Eak2jODMKgYarlkK6LoQaHPTZ1Oo/e6aKjT5W2EvJuBqT4NbuAVGol5xskpfdtrsKdd2sqipX6g3cTg2u4enAqJXl+KBr+ap9OSAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIxLTAzLTAxVDIyOjIwOjEwKzAzOjAwEctyXQAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMS0wMy0wMVQyMjoyMDoxMCswMzowMGCWyuEAAAAASUVORK5CYII=);
  background-position: 96.5% 0;
  background-repeat: no-repeat;
}

.correcto {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAMAAACnUt2HAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAP1BMVEUAAACVu3eVu3eVu3eVu3eVu3eVu3eVu3eVu3eVu3eVu3eVu3eVu3eVu3eVu3eVu3eVu3eVu3eVu3eVu3f///85XOdsAAAAE3RSTlMAEEBQMLCA0OAgoGDwcOWQ+cDWt8muugAAAAFiS0dEFJLfyTUAAAAHdElNRQflAwEWFAqjkdytAAAArklEQVQY002QWxbDIAhEwRrfNrWz/72WIfYkfCheGEBELtPwCkHlafGA2xFulghy4ZlEqgsaULrdNY0Sayt65bV/ZZ1jRMZvJnrAmYnHv2t8A13ZbODcLHyskeblagtNS+5gIetgYaDKF9lZVpkbBnOwLsaYVWLt7P8h49B2Zixx6kyHPT1/kjYf7ODLU9EfW/BfShwcj17lsvaiSLFS4gC3SBu25fjYck3L7NzSH+30CvVR764pAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIxLTAzLTAxVDIyOjIwOjEwKzAzOjAwEctyXQAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMS0wMy0wMVQyMjoyMDoxMCswMzowMGCWyuEAAAAASUVORK5CYII=);
  background-position: 96.5% 0;
  background-repeat: no-repeat;
}

.errorFecha {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAATCAMAAABIkLa5AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAANlBMVEUAAABqFB1qFB1qFB1qFB1qFB1qFB1qFB1qFB1qFB1qFB1qFB1qFB1qFB1qFB1qFB1qFB3///9SQFAtAAAAEHRSTlMAQCBg8MAQUODQgDBwoJCwOHiqygAAAAFiS0dEEeK1PboAAAAHdElNRQflAwEWFAqjkdytAAAAlklEQVQY02WQ6wLFEAyDi7rNTN7/aadu45z8E5p8RbSkNP3JMGDdj+mBAMTTdAEXpYj7cC9YSQbvGZrRqjKezS1yuvW67UwyaaSrJ3VFGKGwrdVP1Eak2jODMKgYarlkK6LoQaHPTZ1Oo/e6aKjT5W2EvJuBqT4NbuAVGol5xskpfdtrsKdd2sqipX6g3cTg2u4enAqJXl+KBr+ap9OSAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIxLTAzLTAxVDIyOjIwOjEwKzAzOjAwEctyXQAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMS0wMy0wMVQyMjoyMDoxMCswMzowMGCWyuEAAAAASUVORK5CYII=);
  background-position: 95% 2px;
  background-repeat: no-repeat;
}

.correctoFecha {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAMAAACnUt2HAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAP1BMVEUAAACVu3eVu3eVu3eVu3eVu3eVu3eVu3eVu3eVu3eVu3eVu3eVu3eVu3eVu3eVu3eVu3eVu3eVu3eVu3f///85XOdsAAAAE3RSTlMAEEBQMLCA0OAgoGDwcOWQ+cDWt8muugAAAAFiS0dEFJLfyTUAAAAHdElNRQflAwEWFAqjkdytAAAArklEQVQY002QWxbDIAhEwRrfNrWz/72WIfYkfCheGEBELtPwCkHlafGA2xFulghy4ZlEqgsaULrdNY0Sayt65bV/ZZ1jRMZvJnrAmYnHv2t8A13ZbODcLHyskeblagtNS+5gIetgYaDKF9lZVpkbBnOwLsaYVWLt7P8h49B2Zixx6kyHPT1/kjYf7ODLU9EfW/BfShwcj17lsvaiSLFS4gC3SBu25fjYck3L7NzSH+30CvVR764pAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIxLTAzLTAxVDIyOjIwOjEwKzAzOjAwEctyXQAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMS0wMy0wMVQyMjoyMDoxMCswMzowMGCWyuEAAAAASUVORK5CYII=);
  background-position: 95% 2px;
  background-repeat: no-repeat;
}

.imgPago {
  height: 70px;
  width: 170px;
  margin: 5px;
  padding: 10px;
  border-radius: 20px;
  border: solid 1px;
}

.imgPagoPSE {
  height: 80px;
  width: 80px;
  margin: 5px;
  padding: 10px;
  border-radius: 20px;
  border: solid 1px;
}

.imgPagoCredito {
  height: 90px;
  width: 200px;
  margin-top: 10px;
  margin-left: 30px;
  padding: 10px;
  border-radius: 20px;
  border: solid 1px;
}

.block1 {
  width: 15px;
  height: 120px;
  border-radius: 15px;
  background-color: black;
  border: 1px solid;
  margin-bottom: -50px;
  margin-left: 4px;
}

.block2 {
  width: 15px;
  height: 70px;
  border-radius: 18px;
  background-color: black;
  margin-bottom: -70px;
  border: 1px solid;
  margin-left: 22px;
  transform: rotate(-140deg);
}

.block3 {
  width: 15px;
  height: 70px;
  border-radius: 18px;
  background-color: black;
  border: 1px solid;
  margin-left: -15px;
  margin-bottom: 0px;
  transform: rotate(140deg);
}

.arrowSliding {
  position: absolute;
  animation: slide 1.5s linear infinite;
}

@keyframes slide {
  0% {
    top: 0px;
  }
  50% {
    top: 60px;
  }
  100% {
    top: 0px;
  }
}

/*Menos de 600px de ancho y mas de 340 de alto(Tipo: Movil Normal)*/
@media (max-width: 700px) and (min-width: 340px) {
  /* .Pedido {
    overflow-x: hidden !important;
    overflow-y: hidden !important;
    height: 80vh;
    width: 100vw;
    position: absolute;
    top: 12%;
  } */

  .atributosTabla {
    font-weight: bold;
    margin-top: 10px;
    font-size: 12px;
  }

  /* #listaProductos {
    position: absolute;
    top: 17%;
    width: 100%;
    height: 70%;
    overflow-y: auto;
    overflow-x: hidden;
  } */

  /* .botonPaso2 {
    margin-left: 22.5%;
    margin-top: 5px;
    width: 55%;
    height: 7%;
    padding-top: 0.5vh !important;
    color: #000000 !important;
    font-size: 150% !important;
    border: solid 3px;
  } */

  /* .botonPaso3 {
    position: absolute;
    bottom: 0%;
    left: 50%;
    transform: translate(-50%, 0%);
    -webkit-transform: translate(-50%, 0%);
    width: 45%;
    height: 50px;
    padding: 0.5vh !important;
    color: #000000 !important;
    font-size: 150% !important;
    border: solid 3px;
  } */

  .columnasBotonPaso {
    position: absolute;
    bottom: 0%;
    right: 0%;
  }

  #imgEslogan {
    display: none;
  }

  /* .totalPedido {
    position: relative;
    bottom: 0%;
    font-size: 20px;
    height: 50px;
    margin-left: 25px;
    margin-top: 42px;
  } */

  .columnasTotalPedido {
    position: absolute;
    bottom: 0%;
    margin-left: 20px;
  }
  /* 
  #paginas {

    position: absolute;
    top: 0%;
    left: 50%;
    transform: translate(-50%, -12%);
    -webkit-transform: translate(-50%, -12%);
    display: flex;
    font-size: 1em;
    width: 100%;
    margin: 2%;
    background: white;
  } */

  .botonPagina {
    height: 30px;
    width: 30px;
    border-radius: 30px;
    border: 2px solid #000000;
    font-family: "Bebas";
    font-size: 15px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-left: 1%;
    margin-right: 1%;
    margin-bottom: 0%;
  }

  .infoPaginas {
    font-family: "Helvetica";
    font-size: 0.8em;
    font-weight: bold;
    margin: 0px;
  }

  .botonBorrarPedido {
    height: 35px;
    width: 24px;
    cursor: pointer;
  }

  .tituloTabla {
    font-weight: bold;
    font-size: 25px;
    background-color: #ffffff;
    z-index: 100;
  }

  /*Formularios*/
  /* .Paso1 {
    overflow-x: hidden !important;
    overflow: auto;
    height: 80%;
    margin-top: 15%;
  } */

  .Paso2 {
    height: 80%;
    margin-top: 15%;
  }

  .CampoRegistroPedido {
    overflow-x: hidden !important;
    overflow: auto;
    height: 100%;
  }

  .CampoRegistroPedido::-webkit-scrollbar:vertical {
    width: 10px !important;
  }

  .Paso3 {
    overflow-x: hidden !important;
    overflow: auto;
    height: 100%;
    margin-top: 12%;
  }

  .formulariosCampo {
    overflow-x: hidden !important;
    overflow: auto;
    /* height: 52vh; */
  }

  .formulariosCampo::-webkit-scrollbar:vertical {
    width: 10px !important;
  }

  .formularioDerecha {
    width: 80%;
    margin-left: 10%;
    font-size: 18px;
  }

  .formularioIzquierda {
    width: 80%;
    margin-left: 10%;
    font-size: 18px;
  }

  .formularioMitad {
    width: 100%;
    padding-left: 1px;
    padding-right: 1px;
  }

  .titulosPaso2 {
    font-size: 1.5em;
  }

  .textoPaso2 {
    font-size: 1em;
  }

  .formulario {
    width: 100%;
    font-size: 1.05em;
  }

  .fontSelect {
    font-size: 0.8em;
  }

  .bancos {
    margin-left: 20px;
    width: 80%;
  }

  .formularioWeb {
    display: none;
  }

  .formularioMovil {
    width: 100%;
  }

  #arrowAnim {
    display: none;
  }
}

/*Menos de 500px de alto(Tipo: Movil Normal Horizontal)*/
@media (max-height: 500px) {
  /* .Pedido {
    overflow-x: hidden !important;
    overflow-y: hidden !important;
    height: 80vh;
    width: 100vw;
    position: absolute;
    top: 12%;
  } */

  .atributosTabla {
    font-weight: bold;
    margin-top: 10px;
    font-size: 12px;
  }

  /* #listaProductos {
    position: absolute;
    top: 130px;
    width: 100%;
    height: 45%;
    overflow-y: auto;
    overflow-x: auto;
  } */

  /* .botonPaso2 {
    position: absolute;
    bottom: -3%;
    left: 50%;
    transform: translate(-50%, 0%);
    -webkit-transform: translate(-50%, 0%);
    width: 45%;
    height: 35px;
    padding-bottom: 40px !important;
    color: #000000 !important;
    font-size: 150% !important;
    border: solid 3px;
  } */
  /* 
  .botonPaso3 {
    position: absolute;
    bottom: 0.1%;
    right: 0.5%;
    transform: translate(-0.5%, 0%);
    -webkit-transform: translate(-0.5%, 0%);
    width: 200px;
    height: 50px;
    padding-top: 5px !important;
    margin-top: 20px !important;
    color: #000000 !important;
    font-size: 25px !important;
    border: solid 3px;
  } */

  #imgEslogan {
    display: none;
  }

  /* .totalPedido {
    position: absolute;
    bottom: -3%;
    left: 1%;
    font-size: 1.5em;
    width: 40%;
    height: 50px;
    margin-left: 15px;
  } */
  /* 
  #paginas {

    position: absolute;
    top: 0%;
    left: 50%;
    transform: translate(-50%, -12%);
    -webkit-transform: translate(-50%, -12%);
    display: flex;
    font-size: 1em;
    width: 100%;
    margin: 2%;
    background: white;
  } */

  .botonPagina {
    height: 30px;
    width: 30px;
    border-radius: 30px;
    border: 2px solid #000000;
    font-family: "Bebas";
    font-size: 15px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-left: 1%;
    margin-right: 1%;
    margin-bottom: 0%;
  }

  .infoPaginas {
    font-family: "Helvetica";

    font-size: 0.8em;
    font-weight: bold;
    margin: 0px;
  }

  .botonBorrarPedido {
    height: 40px;
    width: 29px;
    cursor: pointer;
  }

  .tituloTabla {
    font-weight: bold;
    font-size: 20px;
    background-color: #ffffff;
    z-index: 100;
  }

  /*Formularios*/
  /* .Paso1 {
    overflow-x: hidden !important;
    overflow: auto;
    height: 80%;
    margin-top: 2%;
  } */

  .Paso2 {
    overflow-x: hidden !important;
    overflow: auto;
    height: 50vh;
    margin-top: 20%;
  }

  .Paso3 {
    overflow-x: hidden !important;
    overflow: auto;
    margin-top: 20%;
  }

  .formulariosCampo {
    overflow-x: hidden !important;
    overflow: auto;
    /* height: 49vh; */
  }

  .formularioDerecha {
    width: 80%;
    margin-left: 10%;
    font-size: 18px;
  }

  .formularioIzquierda {
    width: 80%;
    margin-left: 10%;
    font-size: 18px;
  }

  .formularioMitad {
    width: 100%;
    padding-left: 1px;
    padding-right: 1px;
  }

  .titulosPaso2 {
    font-size: 1.05em;
  }

  .textoPaso2 {
    font-size: 0.8em;
  }

  .formulario {
    width: 100%;
    font-size: 1.05em;
  }

  .fontSelect {
    font-size: 0.85em;
  }

  .bancos {
    margin-left: 20px;
    width: 70%;
  }
  .formularioWeb {
    display: none;
  }

  .formularioMovil {
    width: 100%;
  }

  #arrowAnim {
    display: none;
  }
}

/*Menos de 600px de alto y menos de 340px de ancho (Tipo: Movil Pequeño)*/
@media (max-width: 340px) {
  /* .Pedido {
    overflow-x: hidden !important;
    overflow-y: hidden !important;
    height: 80vh;
    width: 100vw;
    position: absolute;
    top: 12%;
  } */

  .atributosTabla {
    font-weight: bold;
    margin-top: 10px;
    font-size: 12px;
  }

  /* #listaProductos {
    position: absolute;
    top: 20%;
    width: 100%;
    height: 65%;
    overflow-y: auto;
    overflow-x: hidden;
  } */
  /* 
  .botonPaso2 {
    margin-left: 22.5%;
    margin-top: 5px;
    width: 55%;
    height: 35px;
    line-height: 35px;
    color: #000000 !important;
    font-size: 20px !important;
    border: solid 3px;
  } */

  /* .botonPaso3 {
    position: absolute;
    bottom: 0%;
    left: 50%;
    transform: translate(-50%, 0%);
    -webkit-transform: translate(-50%, 0%);
    width: 45%;
    height: 7%;
    padding-top: 0.5vh !important;
    color: #000000 !important;
    font-size: 1.2em !important;
    border: solid 3px;
  } */

  .columnasBotonPaso {
    position: absolute;
    bottom: 0%;
    right: 0%;
  }

  .columnasTotalPedido {
    position: absolute;
    bottom: 0%;
    margin-left: 20px;
  }

  #imgEslogan {
    display: none;
  }

  /* .totalPedido {
    position: absolute;
    bottom: 0%;
    left: 1%;
    font-size: 1.3em;
    width: 120px;
    height: 50px;
    margin-left: 15px;
  } */

  .volverMercado {
    margin-top: 5px;
    margin-left: -15px;
  }
  /* 
  #paginas {
    
    margin-bottom: 10px;
    position: absolute;
    top: 1%;
    left: 50%;
    transform: translate(-50%, -12%);
    -webkit-transform: translate(-50%, -12%);
    display: flex;
    font-size: 1em;
    width: 100%;
    margin: 2%;
    background: white;
  } */

  .botonPagina {
    height: 23px;
    width: 23px;
    border-radius: 30px;
    border: 2px solid #000000;
    font-family: "Bebas";
    font-size: 15px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-left: 1%;
    margin-right: 1%;
    margin-bottom: 0%;
  }

  .infoPaginas {
    font-family: "Helvetica";

    font-size: 0.8em;
    font-weight: bold;
    margin: 0px;
  }

  .botonBorrarPedido {
    height: 35px;
    width: 24px;
    cursor: pointer;
  }

  .tituloTabla {
    font-weight: bold;
    font-size: 20px;
    background-color: #ffffff;
    z-index: 100;
  }

  /*Formularios*/
  /* .Paso1 {
    overflow-x: hidden !important;
    overflow: auto;
    height: 80%;
    margin-top: 15%;
  } */

  .Paso2 {
    height: 75%;
    margin-top: 20%;
  }

  .CampoRegistroPedido {
    overflow-x: hidden !important;
    overflow: auto;
    height: 100%;
  }

  .CampoRegistroPedido::-webkit-scrollbar:vertical {
    width: 10px !important;
  }

  .Paso3 {
    overflow-x: hidden !important;
    overflow: auto;
    margin-top: 20%;
  }

  .formulariosCampo {
    overflow-x: hidden !important;
    overflow: auto;
    height: 52vh;
  }

  .formulariosCampo::-webkit-scrollbar:vertical {
    width: 10px !important;
  }

  .formularioDerecha {
    width: 80%;
    margin-left: 10%;
    font-size: 18px;
  }

  .formularioIzquierda {
    width: 80%;
    margin-left: 10%;
    font-size: 18px;
  }

  .formularioMitad {
    width: 100%;
    padding-left: 1px;
    padding-right: 1px;
  }

  .titulosPaso2 {
    font-size: 1.5em;
  }

  .textoPaso2 {
    font-size: 1em;
  }

  .formulario {
    width: 100%;
    font-size: 1.05em;
  }

  .fontSelect {
    font-size: 0.8em;
  }

  .bancos {
    margin-left: 20px;
    width: 90%;
  }

  .formularioWeb {
    display: none;
  }

  .formularioMovil {
    width: 100%;
  }

  #arrowAnim {
    display: none;
  }
}

/*Mas de 600px de ancho y menos de 850px de alto (Tipo: Tablet)*/
@media (min-width: 700px) and (max-height: 850px) {
  /* .Pedido {
    overflow-x: hidden !important;
    overflow-y: hidden !important;
    height: 80vh;
    width: 100vw;
    position: absolute;
    top: 12%;
  } */

  .atributosTabla {
    font-weight: bold;
    margin-top: 50px;
    font-size: 15px;
  }

  /* #listaProductos {
    position: absolute;
    top: 90px;
    width: 100%;
    height: 50%;
    overflow: auto;
  } */

  /* .botonPaso2 {
    position: absolute;
    bottom: 0.1%;
    left: 50%;
    transform: translate(-50%, 0%);
    -webkit-transform: translate(-50%, 0%);
    width: 200px;
    height: 50px;
    padding-top: 5px !important;
    margin-top: 20px !important;
    color: #000000 !important;
    font-size: 25px !important;
    border: solid 3px;
  } */

  #imgEslogan {
    display: none;
  }

  /* .totalPedido {
    position: absolute;
    bottom: 0%;
    left: 20px;
    font-size: 28px;
    width: 280px;
    height: 60px;
    margin-top: 10px;
    margin-left: 10px;
  } */

  .botonPagina {
    height: 40px;
    width: 40px;
    border-radius: 30px;
    border: 2px solid #000000;
    font-family: "Bebas";
    font-size: 25px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-left: 1%;
    margin-right: 1%;
    margin-bottom: 0%;
  }

  .infoPaginas {
    font-family: "Helvetica";

    font-size: 15px;
    font-weight: bold;
    margin: 0px;
  }

  .botonBorrarPedido {
    height: 40px;
    width: 29px;
    cursor: pointer;
  }

  .tituloTabla {
    font-weight: bold;
    font-size: 25px;
    background-color: #ffffff;
    z-index: 100;
  }

  /*Formularios*/
  /* .Paso1 {
    overflow-x: hidden !important;
    overflow: auto;
    height: 80%;
    margin-top: 50px;
  } */

  .Paso2 {
    overflow-x: hidden !important;
    overflow: auto;
    height: 80vh;
    margin-top: 10%;
  }

  /*Formularios*/
  .Paso3 {
    overflow-x: hidden !important;
    overflow: auto;
    margin-top: 12%;
  }

  .formulariosCampo {
    overflow-x: hidden !important;
    overflow: auto;
    /* height: 40vh; */
  }

  .formularioDerecha {
    width: 80%;
    margin-left: 10%;
    font-size: 18px;
  }

  .formularioIzquierda {
    width: 80%;
    margin-left: 10%;
    font-size: 18px;
  }

  .formularioMitad {
    width: 50%;
    padding-left: 1px;
    padding-right: 1px;
  }

  .titulosPaso2 {
    font-size: 1.5em;
  }

  .textoPaso2 {
    font-size: 1em;
  }

  .formulario {
    width: 100%;
    font-size: 1.05em;
  }

  .fontSelect {
    font-size: 0.85em;
  }

  .bancos {
    margin-left: 20px;
    width: 60%;
  }

  .formularioWeb {
    display: none;
  }

  .formularioMovil {
    width: 100%;
  }

  #arrowAnim {
    display: none;
  }
}

/*Mas de 600px de ancho y mas de 850px de alto (Tipo: Pc, Web)*/
/* .Pedido {
  height: auto;
  width: 99%;
  position: absolute;
  top: 15%;
} */
#listaProductos {
  position: relative;
  top: 10%;
  width: 100%;
  height: auto;
  overflow-y: auto;
  overflow-x: auto;
  z-index: -1;
}

.barraPaso3 {
  position: fixed;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
  background: white;
  bottom: 0px;
  width: 100%;
}
.totalPedido {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 5px;
}

.botonPaso3 {
  height: 10%;
  padding: 10px;
  color: #000000;
  font-size: 20px;
  border: solid 3px;
  margin: 15px;
}

@media (min-width: 700px) {
  .atributosTabla {
    font-weight: bold;
    margin-top: 50px;
    font-size: 15px;
  }

  /*.botonPaso2 {
     position: absolute;
    bottom: 0.1%;
    right: 1%;
    width: 200px;
    height: 50px;
    padding-top: 5px !important;
    color: #000000 !important;
    margin-top: 20px !important;
    font-size: 25px !important;
    border: solid 3px;
  } */

  /* .botonPaso3 {
    position: absolute;
    bottom: 0.1%;
    right: 0.5%;
    transform: translate(-0.5%, 0%);
    -webkit-transform: translate(-0.5%, 0%);
    width: 200px;
    height: 50px;
    padding-top: 5px !important;
    margin-top: 20px !important;
    color: #000000 !important;
    font-size: 25px !important;
    border: solid 3px;
  } */

  .columnasBotonPaso {
    position: absolute !important;
    bottom: 0% !important;
    right: 0% !important;
    margin-right: 10px;
  }

  /* .totalPedido {
    position: absolute;
    top: 90%;
    left: 5%;
    right: 20px;
    font-size: 28px;
    width: 300px;
    margin-top: 10px;
    margin-left: 10px;
    margin-bottom: 20px;
  } */

  .columnasTotalPedido {
    position: absolute !important;
    bottom: 7% !important;
    left: 0% !important;
    margin-left: 20px;
  }

  .botonPagina {
    height: 40px;
    width: 40px;
    border-radius: 30px;
    border: 2px solid #000000;
    font-family: "Bebas";
    font-size: 1.3em;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-left: 1%;
    margin-right: 1%;
    margin-bottom: 0%;
  }

  .infoPaginas {
    font-family: "Helvetica";

    font-size: 1em;
    font-weight: bold;
    margin: 0px;
  }

  .botonBorrarPedido {
    height: 40px;
    width: 29px;
    cursor: pointer;
  }

  .tituloTabla {
    font-weight: bold;
    font-size: 25px;
    background-color: #ffffff;
    z-index: 100;
  }

  /*Formularios*/
  .Paso2 {
    overflow-x: hidden !important;
    overflow: auto;
    height: 80vh;
    margin-top: 4%;
  }

  .Paso3 {
    overflow-x: hidden !important;
    overflow: auto;
    margin-top: 4%;
  }

  .formulariosCampo {
    overflow-x: hidden !important;
    overflow-y: auto;
    display: flex;
    /* height: 45vh !important; */
  }

  .formularioDerecha {
    width: 35%;
    margin-left: 10%;
    font-size: 18px;
  }

  .formularioIzquierda {
    width: 35%;
    margin-left: 10%;
    font-size: 18px;
  }

  .formularioMitad {
    width: 50%;
    padding-left: 1px;
    padding-right: 1px;
  }

  .titulosPaso2 {
    font-size: 1.8em;
  }

  .textoPaso2 {
    font-size: 1em;
  }

  .formulario {
    width: 100%;
    font-size: 1.05em;
  }

  .fontSelect {
    font-size: 0.85em;
  }

  .bancos {
    margin-left: 20px;
    width: 30%;
  }

  .formularioMovil {
    display: none;
  }

  .formularioWeb {
    width: 100%;
    display: flex;
  }

  #arrowAnim {
    position: absolute;
    bottom: 250px;
    right: 70px;
    height: 50px;
    width: 50px;
  }
}

.IniciarSesion {
  margin-top: 25vh;
}

.formularioSesion {
  width: 50vw;
  margin-left: 25vw;
  font-size: 18px;
}

.formulariosCampoSesion {
  overflow-x: hidden !important;
  overflow: auto;
  display: flex;
}

.formularioIndividual {
  margin-top: 50px;
  width: 100%;
  font-size: 1.05em;
}

.botonIngresar {
  margin-left: 25%;
  width: 50%;
  height: 50px;
  padding-top: 5px !important;
  margin-top: 20px !important;
  color: #000000 !important;
  font-size: 25px !important;
  border: solid 3px;
}

.botonIngresar:hover {
  color: #ffffff !important;
  background-color: #000000;
  border: solid 3px #000000;
}

/*Menos de 1400px de ancho (Filtro Movil)*/
@media (max-width: 800px) {
  .formularioSesion {
    width: 75vw;
    margin-left: 12.5vw;
    font-size: 18px;
  }

  .volverIndex {
    position: absolute;
    top: 15%;
    transform: translate(0%, -15%);
    -webkit-transform: translate(0%, -15%);
    margin-left: 15px;
    font-size: 25px;
    color: black;
  }

  .registroLink {
    margin-left: 20%;
    width: 60%;
    padding: 5px !important;
    margin-top: 20px !important;
    color: #ffffff !important;
    background-color: #000000;
    font-size: 15px !important;
    border: solid 3px;
  }

  .registroLink:hover {
    color: #000000 !important;
    background-color: #00000000;
    border: solid 3px;
  }
}

/*Mas de 1400px de ancho (Filtro Web)*/
@media (min-width: 1400px) {
  .formularioSesion {
    width: 20vw;
    margin-left: 40vw;
    font-size: 18px;
  }

  .volverIndex {
    position: absolute;
    top: 45%;
    transform: translate(0%, -45%);
    -webkit-transform: translate(0%, -45%);
    margin-left: 30px;
    font-size: 30px;
    color: black;
  }

  .registroLink {
    margin-left: 35%;
    width: 30%;
    padding: 5px !important;
    margin-top: 20px !important;
    color: #ffffff !important;
    background-color: #000000;
    font-size: 15px !important;
    border: solid 3px;
  }

  .registroLink:hover {
    color: #000000 !important;
    background-color: #00000000;
    border: solid 3px;
  }
}

/*Mas de 1400px de ancho (Filtro Web)*/
@media (max-width: 1400px) and (min-width: 800px) {
  .formularioSesion {
    width: 30vw;
    margin-left: 35vw;
    font-size: 18px;
  }

  .volverIndex {
    position: absolute;
    top: 45%;
    transform: translate(0%, -45%);
    -webkit-transform: translate(0%, -45%);
    margin-left: 30px;
    font-size: 30px;
    color: black;
  }

  .registroLink {
    margin-left: 35%;
    width: 30%;
    padding: 5px !important;
    margin-top: 20px !important;
    color: #ffffff !important;
    background-color: #000000;
    font-size: 15px !important;
    border: solid 3px;
  }

  .registroLink:hover {
    color: #000000 !important;
    background-color: #00000000;
    border: solid 3px;
  }
}

.CampoRegistro {
  position: absolute;
  top: 10%;
  width: 100%;
  height: 90%;
  overflow-y: auto;
}

.volverIndex {
  position: absolute;
  top: 15%;
  transform: translate(0%, -15%);
  -webkit-transform: translate(0%, -15%);
  margin-left: 15px;
  font-size: 25px;
  color: black;
}

.btn-Direccion {
  position: relative;
  width: 260px;
  left: 50%;
  transform: translate(-50%, -0%);
  -webkit-transform: translate(-50%, -0%);
  margin-bottom: 30px;
}

/*Menos de 1400px de ancho (Filtro Movil)*/
@media (max-width: 1400px) {
  .botonRegistro {
    margin-left: 35%;
    width: 30%;
    height: 50px;
    padding-top: 5px !important;
    margin-top: 10px !important;
    color: #000000 !important;
    font-size: 20px !important;
    border: solid 3px;
  }

  .botonRegistro:hover {
    color: #ffffff !important;
    background-color: #000000;
    border: solid 3px #000000;
  }

  .volverInicio {
    position: absolute;
    top: 5%;
    transform: translate(0%, -5%);
    -webkit-transform: translate(0%, -5%);
    margin-left: 15px;
    font-size: 25px;
    color: black;
  }
}

/*Mas de 1400px de ancho (Filtro Web)*/
@media (min-width: 1400px) {
  .botonRegistro {
    margin-left: 37.5%;
    width: 25%;
    height: 50px;
    padding-top: 5px !important;
    margin-top: 10px !important;
    color: #000000 !important;
    font-size: 25px !important;
    border: solid 3px;
  }

  .botonRegistro:hover {
    color: #ffffff !important;
    background-color: #000000;
    border: solid 3px #000000;
  }

  .volverInicio {
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);
    -webkit-transform: translate(0%, -50%);
    margin-left: 15px;
    font-size: 25px;
    color: black;
  }
}

/* ESTILOS NORMALES (para escritorios muy grandes)
   ========================================================================= */
.lupa {
  height: 20px;
}
#BuscarFiltro {
  position: absolute;
  top: 4%;
  margin-left: 5%;
  margin-right: 2%;
  text-align: center;
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.contenedorCarrito {
  position: relative;
  display: inline-block;
  text-align: center;
}
.texto-encima {
  position: absolute;
  top: 10px;
  left: 10px;
}
.centrado {
  position: absolute;
  top: 42.5%;
  left: 56%;
  transform: translate(-50%, -50%);
  font-weight: bold;
}

.centerHeader {
  display: flex;
  position: fixed;
  flex-direction: row;
  /* align-content: center; */
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.centerHeaderamd {
  display: none;
}

.centerHeaderExtra {
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  z-index: 200;
  padding-right: 0.5rem;
}

#SecondHeader {
  background-color: black;
  height: 2.5rem;
  min-width: 100%;
  width: 90%;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: fixed;
  top: 0px;
  z-index: 10;
  overflow: hidden;
}

#SecondHeaderamd{
  display: none;
}

#IconHeader {
  position: absolute;
  right: 77%;
  top: -18%;
  object-fit: contain;
}
#IconHeaderMayo {
  position: relative;
  font-family: Poppins-SemiBoldItalic;
  margin: 0%;
  font-size: 0.72em;
  padding: 6px;
  margin-right: 5px;
  background-color: black;
  color: white;
}

#IconHeaderMayo:hover {
  background-color: white;
  color: black;
}

.botonHeader {
  width: 34px;
}

.RedesSociales {
  height: 33px;
  margin: 1%;
  padding: 4px;
}
.barraHeader {
  color: white;
  font-size: 27px;

  letter-spacing: 5px;
}

#ImagenBolsa {
  margin-left: 10px;
  position: absolute;
  right: 1%;
  top: -23%;
  object-fit: contain;
  height: 35px;
  width: 37px;
}

#ImagenCroatta {
  height: 1.7rem;
  width: 100%;
  object-fit: contain;
}

.CantidadCarrito {
  font-weight: bold;
  font-size: 12px;
}
#espacioLogo {
  position: absolute;
  display: flex;
  width: 100%;
  /* align-content: center; */
  justify-content: center;
  align-items: center;
  /* z-index: -1 !important; */
}

.textHeader {
  margin: 0;
  margin-left: 3vw;
  margin-right: 3vw;
  text-align: center;
  font-family: "Arial_Bold";
  color: white;
  letter-spacing: "1px";
}

.btn-active::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, red, blue, deeppink, blue, red, blue, deeppink, blue);
    background-size: 800%;
    filter: blur(8px);

}

/* RESPONSIVE
   ========================================================================= /
 
/ Tablets en horizonal y escritorios normales
   ------------------------------------------------------------------------- */
@media (min-width: 768px) and (max-width: 1199px) {
  .botonHeader {
    width: 28px;
  }

  #IconHeader {
    position: absolute;
    right: 71%;
    top: -23%;
    object-fit: contain;
  }

  .barraHeader {
    color: white;
    font-size: 25px;
    letter-spacing: 4px;
  }
  /* #espacioLogo {
    position: absolute;
    left: 49%;
    display: flex;
    transform: translate(-50%, 0%);
    -webkit-transform: translate(-50%, 0%);
    align-items: center;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
  } */
}

/* Móviles en horizontal o tablets en vertical
   ------------------------------------------------------------------------- */
@media (max-width: 767px) {
  .botonHeader {
    width: 28px;
  }

  /* #espacioLogo {
    position: absolute;
    left: 30%;
    display: flex;
    transform: translate(-50%, 0%);
    -webkit-transform: translate(-50%, 0%);
    align-items: center;

    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
  } */
  #ImagenCroatta {
    width: 100%;
  }
  #IconHeader {
    position: absolute;
    right: 77%;
    top: -33%;
    object-fit: contain;
  }
  #ImagenBolsa {
    position: absolute;
    right: 1%;
    top: -28%;
    object-fit: contain;
    height: 35px;
    width: 37px;
  }
  .CantidadCarrito {
    font-weight: bold;
    font-size: 10px;
  }
  .barraHeader {
    color: white;
    font-size: 22px;
    letter-spacing: 3px;
  }
   .oculto {
    display: none !important;
  } 

  .RedesSociales {
    height: 30px;
    margin: 1%;
    padding: 2px;
  }
}

/* Móviles en vertical
   ------------------------------------------------------------------------- */
@media (max-width: 480px) {
  .botonHeader {
    width: 28px;
  }

  .barraHeader {
    color: white;
    font-size: 15px;
    letter-spacing: 2px;
  }
  .oculto {
    display: none !important;
  }
}

@media (max-width: 520px) {
   #IconHeaderMayo {
    display: none;
  }
}


@media (max-width: 440px) {
  .botonHeader {
    width: 26px;
  }
 

  .oculto {
    display: none !important;
  }
}
@media (max-width: 320px) {
  .botonHeader {
    width: 24px;
  }

  #IconHeader {
    position: absolute;
    right: 77%;
    top: -40%;
    object-fit: contain;
  }
  /* #espacioLogo {
    position: absolute;
    left: 36%;
    display: flex;
    transform: translate(-50%, 0%);
    -webkit-transform: translate(-50%, 0%);
    align-items: center;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
  } */
  #ImagenBolsa {
    position: absolute;
    right: 1%;
    top: -30%;
    object-fit: contain;
    height: 28px;
    width: 37px;
  }

  .oculto {
    display: none !important;
  }
}

/* ESTILOS NORMALES (para escritorios muy grandes)
   ========================================================================= */
p.size {
  font-size: 20px !important;
  font-weight: 900;
}

.checkProduct {
  /* transform: scale(2); */
  -webkit-appearance: none;
          appearance: none;
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
  width: 40px;
  height: 40px;
  appearance: none;
  border: 1px solid #888;
}

.checkProduct:checked {
  background-color: #55c96b;
}

.fichaProduct {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
}

/* RESPONSIVE
   ========================================================================= /
 
/ Tablets en horizonal y escritorios normales
   ------------------------------------------------------------------------- */
@media (min-width: 768px) and (max-width: 1199px) {
  p.size {
    font-size: 20px !important;
    font-weight: 900;
  }
}

/* Móviles en horizontal o tablets en vertical
   ------------------------------------------------------------------------- */
@media (max-width: 767px) {
  p.size {
    font-size: 18px !important;
    font-weight: 900;
  }
}

/* Móviles en vertical
   ------------------------------------------------------------------------- */
@media (max-width: 480px) {
  p.size {
    font-size: 15px !important;
    font-weight: 900;
  }
}

.transicion {
  overflow: hidden;
}

.img-producto {
  object-fit: cover;
  transition: transform 2s ease-in-out;
}

.img-producto:hover {
  transform: scale(1.05);
}

.variantes {
  margin-top: 5px !important;
  margin: 2vw;
  height: 3em;
}

.variantes img {
  margin: 1px;
  width: 2.5em;
  /* border-radius: 20px; */
  border: solid 1px rgb(177, 177, 177);
}

.variantes p {
  margin: 0;
  font-family: Poppins-Light;
  font-weight: bold;
  font-size: large;
}

.precios {
  display: flex;
  flex-direction: row;
}

.descuento {
  margin-left: 18px !important;
  font-family: Poppins-Light;
  font-weight: bold;
  color: red;
  text-decoration: line-through;
  font-size: medium !important;
}

.contenedor-compra {
  position: relative;
  z-index: 10;
  background: white;
  text-align: center;
}

.btn-compra {
  margin-top: 5px;
  background-color: white;
  border: solid 1px black;
  border-radius: 50px;
  padding-left: 8px;
  padding-right: 8px;
  font-family: Poppins-Bold;

}

.btn-compra:hover {
  background-color: rgb(0, 0, 0);
  color: white;
}

.prev {
  color: red;
  text-decoration: line-through;
  font-size: medium !important;
}

@media (max-width: 400px) {
  .modal-compra {
    margin-top: 10vh;
  }
  .variantes img {
    width: 1.8em;
  }
}
.modal-compra {
  display: flex;
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 20;
  margin-top: 10rem;
  flex-direction: column;
  align-items: center;
}

.contenedor {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 360px;
  height: 65%;
  align-items: center;
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 0px 20px 1px rgba(0,0,0,0.84);
}

.close {
  position: absolute;
  right: 5px;
  top: 5px;
}

.btn-close {  
  padding: 0.5em;
  background-color: transparent;
  color: black;
  font-size: 1.5rem;
}

.info {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 80%;
  margin: 20px;
}

.info div h5 {
  font-family: Poppins-Light;
  font-weight: bold;
}

.info div h6 {
  font-family: Poppins-Light;
  font-weight: bold;
  color: red;
  text-decoration: line-through;
}

.img-muestra {
  width: 8em;
  /* box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.05); */
}

.title-m {
  font-family: Poppins-Bold;
  text-align: center;
}

.variantes-m {
  text-align: center;
}

.variantes-m img {
  width: 2.5em;
  margin: 5px;
  cursor: pointer;
  border-radius: 20px;
  border: solid 1px rgb(177, 177, 177);
}

.tallas {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}

.tallas div img {
  margin: 5px;
  cursor: pointer;
}

.unidades {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45%;
  height: 40px;
  border: solid 1px black;
  border-radius: 20px;
  margin-top: 10px;
}

.unidades input {
  width: 50%;
  outline: none;
  text-align: center;
}

.und-input::-webkit-inner-spin-button,
.und-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.unidades button {
  background-color: transparent;
  width: 15px;
}

.addCar {
  padding: 10px;
  background-color: black;
  color: white;
  border-radius: 20px;
  height: 40px;
  margin-top: 10px;
}

.prev-m {
  color: red;
  font-weight: 500;
  text-decoration: line-through;
  font-size: medium !important;
}

@media (max-width: 426px) {
  .modal-compra {
    margin-top: 12vh;
  }
  .contenedor {
    height: 80%;
  }
}
/*=========================
  Icons
 ================= */
* {
  margin: 0;
  padding: 0;
}
.cp-text {
  color: #000000;
  text-shadow: 0 1px rgba(255, 255, 255, 0.1);
  margin-top: 20px;
}

/* footer social icons */
.footer {
  background-color: #f8f9fa;
  padding-top: 10px;
  text-align: center;
}

ul.social-network {
  list-style: none;
  display: inline;
  margin: auto;
}
ul.social-network li {
  display: inline;
  margin: 0 10px;
}

/* footer social icons */
.social-network a.icoFacebook:hover {
  background-color: #3b5998;
}
.social-network a.icoTwitter:hover {
  background-color: #33ccff;
}
.social-network a.icoMedium:hover {
  background-color: #00ab6c;
}
.social-network a.icoQuora:hover {
  background-color: #aa2200;
}
.social-network a.icoLinkedin:hover {
  background-color: #007bb7;
}
.social-network a.icoInstagram:hover {
  background: #f09433;
  background: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#f09433", endColorstr="#bc1888", GradientType=1);
}

.social-network a.icoRss:hover i,
.social-network a.icoFacebook:hover i,
.social-network a.icoTwitter:hover i,
.social-network a.icoMedium:hover i,
.social-network a.icoVimeo:hover i,
.social-network a.icoLinkedin:hover i {
  color: #fff !important;
}

.social-circle li a {
  display: inline-block;
  position: relative;
  margin: 0 auto 0 auto;
  border-radius: 50%;
  text-align: center;
  width: 30px;
  height: 30px;
  font-size: 22px;
}

.social-circle li i {
  margin: 0;
  line-height: 30px;
  text-align: center;
}

.social-circle li a:hover i,
.triggeredHover {
  -moz-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  -ms--transform: rotate(360deg);
  transform: rotate(360deg);
  transition: all 0.2s;
}

.social-circle i {
  color: #fff !important;
  transition: all 0.8s;
}

.social-circle a {
  background-color: #000000;
}

@media screen and (max-width: 500px) {
  ul.social-network li {
    display: inline;
    margin: 0 5px;
  }
}
@media screen and (max-width: 450px) {
  ul.social-network li {
    display: inline;
    margin: 0 5px;
  }

  .social-circle li a {
    display: inline-block;
    position: relative;
    margin: 0 auto 0 auto;
    border-radius: 50%;
    text-align: center;
    width: 30px;
    height: 30px;
    font-size: 16px;
  }

  .social-circle li i {
    margin: 0;
    line-height: 30px;
    text-align: center;
  }
}
@media screen and (max-width: 350px) {
  ul.social-network li {
    display: inline;
    margin: 0 2px;
  }

  .social-circle li a {
    display: inline-block;
    position: relative;
    margin: 0 auto 0 auto;
    border-radius: 50%;
    text-align: center;
    width: 30px;
    height: 30px;
    font-size: 16px;
  }

  .social-circle li i {
    margin: 0;
    line-height: 30px;
    text-align: center;
  }
}

.content {
  display: none;
  justify-content: center;
  grid-gap: 5vw;
  gap: 5vw;
  z-index: 12;
}

.button_slide {
  color: rgb(0, 0, 0);
  background-color: rgba(255, 255, 255, 0.5);
  border: 2px solid rgb(0, 0, 0);
  padding: 0px 5px;
  display: inline-block;
  font-size: 3.5vw;
  cursor: pointer;
  box-shadow: inset 0 0 0 0 #cc00ff94;
  transition: ease-out 0.4s;
}

.slide_right {
  box-shadow: inset 400px 0 0 0 #000000;
  color: rgb(255, 255, 255);
}

@media (max-width: 767px) {
  .content {
    display: flex;
  }
}
.modal-main {
  display: flex;
  align-items: center;
  width: 100%;
  position: fixed;
  flex-direction: column;
  z-index: 20;
  top: calc(50% - 10rem);
}

.content-modal {
  box-shadow: black 0px 0px 10px;
  background-color: white;
  border-radius: 10px;
  width: 500px;
}

.header-modal {
  display: flex;
  justify-content: flex-end;
  /* border-bottom: 1px solid #e0e0e0; */
  padding-right: 0.8em;
  padding-top: 0.5em;
}

.header-modal button {
  
  background-color: transparent;
  font-size: larger;
}

.content-promo {
  text-align: center;
  padding-top: 0 !important;
  padding: 2em;
}

.content-promo h2 {
  font-weight: 400;
  margin-bottom: 20px;
}



@media (max-width: 400px) {
  .content-modal {
    width: 350px;
  }
}
.div-container {
  margin-bottom: 5rem;
  min-width: 62vw;
}

.div-container form div div {
  margin-bottom: 15px;
}

.div-container form div div input {
  margin-bottom: 0 !important;
}

.div-container form div div select {
  font-size: small;
  padding: 0;
}

.options {
  color: grey;
}

.div-container form div div span {
  color: red;
  font-size: small;
  margin: 0;
}

.form-display {
  display: flex;
  flex-direction: row;
}

.form-column {
  flex: 1 1;
  padding: 15px;
}

@media(max-width: 768px) {
  .form-display {
    flex-direction: column;
  }

  .form-column {
    padding: 10%;
  }
}
/* ESTILOS NORMALES (para escritorios muy grandes)
   ========================================================================= */
.Pedido {
  height: auto;
  width: 100%;
  position: absolute;
  top: 15%;
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 1%;
}

#paginas {
  display: flex;
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 2%;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 100%;
}
.Paso1 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-content: center;
  justify-content: space-around;
  align-items: center;
  z-index: 1;
}
.flechaDerechaPedido {
  height: 26px;
  width: 15px;
  margin-left: 2%;
}

.cantidadPedido {
  font-weight: bold;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  align-content: flex-start;
}
#titulosMovil {
  display: none;
}
#datosMovil {
  display: none;
}
.botonPaso1 {
  height: 10%;
  padding: 10px;
  color: #000000;
  font-size: 29px;
  border: solid 3px;
  margin: 2px;
}
.botonPaso2 {
  height: 10%;
  padding: 10px;
  color: #000000;
  font-size: 29px;
  border: solid 3px;
  margin: 2px;
}
.BarraTotal {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  padding-left: 5%;
  padding-right: 5%;
  position: fixed;
  width: 100%;
  font-size: 27px;
  bottom: 0px;
  border-top: black solid;
  background: white;
  z-index: 100;
}

#facturacion {
  border: solid 1px gray;
  font-size: 1.8rem;
  width: 150px;
  text-align: center;
}

.btn-add {
  height:45px;
  width:45px;
  background:black;
  color:white;
}

#remision {
  border: solid 1px gray;
  font-size: 1.8rem;
  width: 150px;
  text-align: center;
}

/* RESPONSIVE
   ========================================================================= /
 
/ Tablets en horizonal y escritorios normales
   ------------------------------------------------------------------------- */
@media (min-width: 768px) and (max-width: 1199px) {
  .botonPaso1 {
    font-size: 27px;
  }
  .botonPaso2 {
    font-size: 27px;
  }
  .BarraTotal {
    font-size: 25px;
  }
}

/* Móviles en horizontal o tablets en vertical
   ------------------------------------------------------------------------- */
@media (max-width: 767px) {
  #titulosMovil {
    display: table-header-group;
  }
  #titulosWeb {
    display: none;
  }

  #datosWeb {
    display: none;
  }
  #datosMovil {
    display: table-row;
  }
  .botonPaso1 {
    font-size: 25px;
  }
  .botonPaso2 {
    font-size: 25px;
  }
  .BarraTotal {
    font-size: 23px;
  }
}

/* Móviles en vertical
   ------------------------------------------------------------------------- */
@media (max-width: 480px) {
  .botonPaso1 {
    font-size: 23px;
    padding: 0px;
  }
  .botonPaso2 {
    font-size: 23px;
    padding: 0px;
  }
  .BarraTotal {
    font-size: 21px;
  }
}

@media (max-width: 440px) {
  .botonPaso1 {
    font-size: 21px;
  }
  .botonPaso2 {
    font-size: 21px;
  }
  .BarraTotal {
    font-size: 19px;
  }
}

.imgPay {
    height: 47%;
    object-fit: scale-down;
}

.imgPayEfectivo {
    height: 265px;
    
}

@media (max-width: 960px) and (min-width: 201px) {
    .imgPayEfectivo {
        height: 130px;
        object-fit: contain;
        
    }
  }

.contentPay {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;

}

.grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    justify-items: center;
    align-items: center;
  }

  .grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    justify-items: center;
    align-items: center;
  }
  
  .grid-item {

    text-align: center;
  }
:root {
  --white: #FFFFFF;
  --black: #000000;
  --very-light-pink: #C7C7C7;
  --text-input-field: #F7F7F7;
  --hospital-green: #ACD9B2;
  --sm: 14px;
  --md: 16px;
  --lg: 18px;
}
body {
  margin: 0;
  font-family: 'Quicksand', sans-serif;
}
.login {
  width: 100%;
  height: 100vh;
  display: grid;
  place-items: center;
}
.form-container {
  display: grid;
  grid-template-rows: auto 1fr auto;
  width: 300px;
}
.logo {
  width: 50px;
  margin-bottom: 15px;
  justify-self: center;
  /* display: none; */
}
.title {
  font-size: 18px;
  font-size: var(--lg);
  margin-bottom: 12px;
  text-align: center;
}
.subtitle {
  color: #C7C7C7;
  color: var(--very-light-pink);
  font-size: 16px;
  font-size: var(--md);
  font-weight: 300;
  margin-top: 0;
  margin-bottom: 32px;
  text-align: center;
}
.form {
  display: flex;
  flex-direction: column;
}
.label {
  font-size: 14px;
  font-size: var(--sm);
  font-weight: bold;
  margin-bottom: 4px;
}
.input {
  background-color: #F7F7F7;
  background-color: var(--text-input-field);
  border: none;
  border-radius: 8px;
  height: 30px;
  font-size: 16px;
  font-size: var(--md);
  padding: 6px;
  margin-bottom: 12px;
}
.primary-button {
  background-color: #ACD9B2;
  background-color: var(--hospital-green);
  border-radius: 8px;
  border: none;
  color: #FFFFFF;
  color: var(--white);
  width: 100%;
  cursor: pointer;
  font-size: 16px;
  font-size: var(--md);
  font-weight: bold;
  height: 50px;
}
.login-button {
  margin-top: 14px;
  margin-bottom: 30px;
}

.botonPaso2 {
    height: 50px;
    padding: 10px;
    color: #000000;
    font-size: 20px;
    border: solid 3px;
    margin: 2px;
  }

@media (max-width: 640px) {
  .logo {
    /* display: block; */
  }
}
.botonLimpiarFiltros {
  height: 50px;
  padding-top: 5px !important;
  margin-top: 20px !important;
  margin-left: 40px !important;
  margin-right: 40px !important;
  color: #000000 !important;
  font-size: 25px !important;
  border: solid 3px;
}

.VideoTop {
  margin-top: 0px;
}

.Precios {
  font-size: 25px;
  font-weight: bold;
}
.ValuesPrecio {
  font-size: 25px;
  font-weight: bold;
  text-align: center;
}
.cel {
  max-height: 473px;
  object-fit: contain;
  margin-top: 15px;
  border-radius: 20px;
}

.titles {
  text-align: inherit;
  font-size: 50px;
  font-weight: bold;
}
.parrafo {
  text-align: justify;
  font-size: 20px;
  font-weight: 400;
}

.Logos {
}

.pse {
}

.images {
  height: 60px;
}

.toplanding {
  margin-top: 200px;
}

.compra {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.pagoM {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-left: 12px;
}
@media only screen and (max-width: 1100px) {
  .botonLimpiarFiltros {
    height: 50px;
    padding-top: 5px !important;
    margin-top: 20px !important;
    margin-left: 40px !important;
    margin-right: 40px !important;
    color: #000000 !important;
    font-size: 22px !important;
    border: solid 3px;
  }

  .VideoTop {
    margin-top: -40px;
  }
  .Precios {
    font-size: 15px;
    font-weight: bold;
  }
  .ValuesPrecio {
    font-size: 15px;
    font-weight: 500;
    text-align: center;
  }
}

@media only screen and (max-width: 650px) {
  .Precios {
    font-size: 15px;
    font-weight: bold;
  }
  .ValuesPrecio {
    font-size: 12px;
    font-weight: 500;
    text-align: center;
  }
  .titles {
    text-align: inherit;
    font-size: 31px;
    font-weight: bold;
  }
  .Logos {
    width: inherit;
  }
  .pse {
    width: 40px;
  }
  .images {
    height: 35px;
  }
  .toplanding {
    margin-top: 100px;
  }
  .compra {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100px;
  }
  .pagoM {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left: 12px;
    width: 136px;
  }
}

@media only screen and (max-width: 350px) {
  .botonLimpiarFiltros {
    height: 50px;
    padding-top: 5px !important;
    margin-top: 20px !important;
    margin-left: 40px !important;
    margin-right: 40px !important;
    color: #000000 !important;
    font-size: 19px !important;
    border: solid 3px;
  }
  .Precios {
    font-size: 15px;
    font-weight: bold;
  }
}

.form-group input[type="text"],
[type="email"],
[type="date"],
[type="password"],
select,
textarea {
  border: none;
  border-bottom: solid 2px #474544;
  color: #474544;
  font-size: 0.8em;
  font-weight: 400;
  letter-spacing: 1px;
  margin-bottom: 30px;
  width: 100%;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  transition: all 0.3s;
}

.botones {
  background: black !important;
}

.textoTablas {
  font-size: 15px;
}
.Periodo {
  font-size: 30px;
}
.containedor {
  padding-top: 50vh;
  align-content: center !important;
}
.center_element {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media only screen and (max-width: 600px) {
  .textoTablas {
    font-size: 8px;
  }
  .Periodo {
    font-size: 20px;
  }

  .table td {
    padding: 4px;
    vertical-align: middle !important;
  }
  table.table th,
  table.table td,
  table.table tr {
    align-content: center;
    vertical-align: middle !important;
    text-align: center;
  }
  .wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
  }

  .press {
    display: block; /* Remove bullet points; allow greater control of positioning */
    padding: 0; /* Override defaults for lists */
    margin: 0; /* Override defaults for lists */
    width: 100%; /* Get the row full width */
  }

  .press li {
    display: inline-block; /* Get all images to show in a row */
    width: 25%; /* Show 4 logos per row */
    text-align: center; /* Centre align the images */
  }

  @media (max-width: 960px) and (min-width: 501px) {
    .press li {
      width: 50%;
    } /* Show 2 logos per row on medium devices (tablets, phones in landscape) */
  }

  @media (max-width: 500px) {
    .press li {
      width: 100%;
    } /* On small screens, show one logo per row */
  }
  
  
}

@media (min-width: 1300px) {
  .container {
      max-width: 1340px !important;
    }
  }
.ContenedorDetalle {
  height: 100%;
  width: 100%;
  background-color: white;
  position: absolute;
  top: 11%;
  overflow-y: auto;
  overflow-x: hidden;
}
.row {
  width: 100% !important;
  margin: 0px !important;
  padding: 0px !important;
}
.container {
  width: 100% !important;
}
.altura {
  height: 99vh !important;
  margin: 0px !important;
  padding: 0px !important;
  text-align: -webkit-center;
}

.ImgDetalle {
  height: 100%;
  width: 90%;
}
.imgMedidasDetalle {
  width: 75vw;
  margin-bottom: 10px;
}

.ImgRelacionados {
  height: 40vh;
  width: auto;
}
.carousel {
  width: 100% !important;
}
.lineaDetalle {
  color: #000000;
  background-color: #000000;
  height: 3px;
  border-color: #000000;
  margin-bottom: 0px;
  margin-top: 0px;
  /*margin-left: 20px;
    margin-right: 20px;*/
}

.FuenteBebas {
  font-family: "Bebas";
}

.TipografiaRel {
  font-size: 10px;
}

.TipografiaGeneral {
  font-size: 40px;
  margin: 0px;
}

.RelDatos {
  display: flex;
  justify-content: space-evenly;
  color: black;
  font-family: Bebas;
  height: 20px;
}

/* .imgMedidasDetalle {
  width: 21vw;
  margin-bottom: 20px;
} */

.TopFlecha {
  margin-bottom: 20px;
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  align-content: center;
  justify-content: center;
}
.letero {
  font-size: 26px;
}
.tipoCompra img {
  height: 39px;
  width: 39px;
  margin: 5px;
}
.detalleOculto2 {
  display: none;
}

.image-gallery {
  margin: 0 auto;
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.image-gallery-slide {
  height: 80vh;
  margin: 0px !important;
  padding: 0px !important;
}
.image-gallery-slide img {
  height: 80vh;
  width: 100%;
}

@media only screen and (max-width: 800px) {
  .RelDatos {
    display: flex;
    justify-content: space-evenly;
    color: black;
    font-family: Bebas;
    height: 20px;
    /*justify-content: space-between;
        width: 27vw;
        font-size: 10px;*/
  }

  .ImgDetalle {
    height: 100%;
    width: 100%;
  }
  /* .ContenedorDetalle {
    margin-top: 20%;
  } */
}

@media only screen and (max-width: 600px) {
  /* .ContenedorDetalle {
    margin-top: 23%;
  } */

  .RelDatos {
    display: flex;
    /*justify-content: space-evenly;*/
    color: black;
    font-family: Bebas;
    height: 25px;
    justify-content: space-between;
    width: 27vw;
    font-size: 11px;
    flex-direction: column;
  }

  .TopFlecha {
    /*margin-top: 102px;*/
    margin-bottom: 10px;
  }

  .TipografiaGeneral {
    font-size: 25px;
    margin: 0px;
  }

  .ImgDetalle {
    width: 100%;
  }
}

#myCarousel img {
  height: 90vh;
  width: auto;
  margin: 0px;
  display: contents;
}

#thumbSlider .carousel-inner {
  padding-left: 0rem;
  padding-right: 0rem;
}
#thumbSlider .carousel-inner .row {
  overflow: hidden;
}
#thumbSlider .carousel-inner .thumb:hover {
  cursor: pointer;
}
#thumbSlider .carousel-inner .thumb.active img {
  opacity: 1;
}
#thumbSlider .carousel-inner img {
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  opacity: 0.5;
}
#thumbSlider .carousel-inner img:hover {
  opacity: 1;
}
#thumbSlider .carousel-inner .carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20fill='%23000'%20viewBox='0%200%208%208'%3E%3Cpath%20d='M5.25%200l-4%204%204%204%201.5-1.5-2.5-2.5%202.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}
#thumbSlider .carousel-inner .carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20fill='%23000'%20viewBox='0%200%208%208'%3E%3Cpath%20d='M2.75%200l-1.5%201.5%202.5%202.5-2.5%202.5%201.5%201.5%204-4-4-4z'/%3E%3C/svg%3E");
}

@media (max-width: 767px) {
  .detalleOculto {
    display: none !important;
  }
  .detalleOculto2 {
    display: contents !important;
  }

  #thumbSlider .carousel-inner img {
    height: 20vh;
    width: auto;
    object-fit: contain;
  }
  #myCarousel img {
    height: 60vh;
    margin: 0;
  }
  .altura {
    height: 60vh !important;
  }

  .ImgRelacionados {
    height: 40vh;
    width: auto;
  }
}
@media (min-width: 768px) and (max-width: 1199px) {
  .ImgRelacionados {
    height: 20vh;
    width: auto;
  }
}
@media (max-width: 480px) {
  .ImgRelacionados {
    height: 20vh;
    width: auto;
  }
}

.navbar {
    background-color: black;
    
    display: flex;
    justify-content: start;
    align-items: center;
    z-index: 155;
  }
  
  .menu-bars {
    margin-left: 2rem;
    font-size: 2rem;
    background: none;
    z-index: 155;
  }
  
  .nav-menu {
    background-color: black;
    width: 250px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: -100%;
    transition: 850ms;
    z-index: 155;
  }
  
  .nav-menu.active {
    left: 0;
    transition: 350ms;
    z-index: 155;
  }
  
  .nav-text {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 8px 0px 8px 16px;
    list-style: none;
    height: 60px;
  }
  
  .nav-text a {
    text-decoration: none;
    color: #f5f5f5;
    font-size: 18px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
  }
  
  .nav-text a:hover {
    background-color: #1a83ff;
  }
  
  .nav-menu-items {
    width: 100%;
  }
  
  .navbar-toggle {
    background-color: black;
    width: 100%;

    display: flex;
    justify-content: start;
    align-items: center;
  }
  
  span {
    margin-left: 16px;
  }

.croatta {
  display: flex;
  width: 100px;
  height: 43px;
  justify-content: space-around;
}
.struct{
    display: flex;
    justify-content: space-around;
    margin-top: 9vh;
}

.table-send th {
  border: 1px solid rgb(160 160 160);
  padding: 0px 10px;
}

.table-send td {
  border: 1px solid rgb(160 160 160);
  padding: 0px 10px;
}
/* ESTILOS NORMALES (para escritorios muy grandes)
   ========================================================================= */

#Categorias {
  text-align: center;
}

.col-6-p {
  width: 50%;
  box-sizing: border-box;
  padding-right: 15px;
  padding-left: 15px;
}

.col-12-p {
  width: 100%;
  box-sizing: border-box;
  padding: 10vw;
  padding-right: 15px;
  padding-left: 15px;
}

#filaIndex {
  padding: 0.9rem;
  display: grid;
  grid-gap: 0.5rem;
  grid-column-gap: 0.5rem;
  column-gap: 0.5rem;
  grid-auto-rows: auto;
  grid-template-columns: repeat(auto-fill, minmax(29rem, 1fr));
  justify-content: center;
  align-items: center;
  justify-items: center;
  align-content: center;
}
.centradoBoton {
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  position: relative;
  font-weight: bold;
}
.centradoBotonIndex {
  position: absolute;
  top: 85%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
}
.centradoBotonConcurso {
  position: absolute;
  top: 30vw;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 1vw;
  font-size: 3vw;
}

.center {
  display: block;
  margin-top: 0.5rem;
}
#tituloCentrado {
  align-items: center;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: nowrap;
  flex-direction: row;
}

.carousel {
  background: #fff;
}

/*
Forces image to be 100% width and not max width of 100%
*/
.carousel-item {
  height: 70vh;
}
.img-fluid {
  width: 100%;
}

.carousel-caption {
  color: black !important;
}

/* 
anchors are inline so you need ot make them block to go full width
*/
.carousel-item a {
  display: block;
  width: 100%;
}

#Boton {
  background-color: black;
  border: black;
  padding: 1px;
  background-color: #000000;
  color: #ffffff;
  -webkit-text-decoration: auto;
          text-decoration: auto;
  text-transform: uppercase;
  backface-visibility: hidden;
  font-size: 36px;
  letter-spacing: 12px;
  /* width: 88%; */
}
.position {
  margin-top: -17% !important;
}

/* RESPONSIVE
   ========================================================================= /
 
/ Tablets en horizonal y escritorios normales
   ------------------------------------------------------------------------- */
@media (min-width: 768px) and (max-width: 1199px) {
  .carousel-item {
    height: 60vh;
  }
  .carousel {
    background: #fff;
  }

  #filaIndex {
    display: grid;
    grid-gap: 1rem;
    grid-row-gap: 0.5rem;
    row-gap: 0.5rem;
    grid-column-gap: 0.5rem;
    column-gap: 0.5rem;
    grid-auto-rows: auto;
    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
    justify-content: center;
    align-items: center;
    justify-items: center;
    align-content: center;
  }
  #Boton {
    background-color: black;
    border: black;
    padding: 1px;
    background-color: #000000;
    color: #ffffff;
    -webkit-text-decoration: auto;
            text-decoration: auto;
    text-transform: uppercase;
    backface-visibility: hidden;
    letter-spacing: 6px;
    /* width: 74%; */
    font-size: 34px;
  }
  .position {
    margin-top: -17% !important;
  }
}

/* Móviles en horizontal o tablets en vertical
   ------------------------------------------------------------------------- */
@media (max-width: 767px) {
  .carousel-item {
    height: 60vh;
    margin: 0%;
  }
  .carousel {
    background: #fff;
  }

  #filaIndex {
    display: grid;
    grid-gap: 1rem;
    grid-gap: 1em;
    gap: 1em;
    grid-column-gap: 1rem;
    column-gap: 1rem;
    grid-auto-rows: auto;
    grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
    justify-content: center;
    align-items: center;
    justify-items: center;
    align-content: center;
  }
  #Boton {
    background-color: black;
    border: black;
    background-color: #000000;
    color: #ffffff;
    -webkit-text-decoration: auto;
            text-decoration: auto;
    text-transform: uppercase;
    backface-visibility: hidden;
    letter-spacing: 9px;
    /* width: 74%; */
    font-size: 26px;
  }
  .position {
    margin-top: -73% !important;
  }
}

/* Móviles en vertical
   ------------------------------------------------------------------------- */
@media (max-width: 480px) {
  .carousel-item {
    height: 50vh;
  }
  .carousel {
    background: #fff;
  }

  .col-6-p {
    width: 100%;
    box-sizing: border-box;
    padding-right: 15px;
    padding-left: 15px;
  }

  #filaIndex {
    display: grid;
    grid-gap: 1rem;
    grid-gap: 1rem;
    gap: 1rem;
    grid-column-gap: 1rem;
    column-gap: 1rem;
    grid-auto-rows: auto;
    grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));
    justify-content: center;
    align-items: center;
    justify-items: center;
    align-content: center;
  }
  #Boton {
    background-color: black;
    border: black;
    background-color: #000000;
    color: #ffffff;
    -webkit-text-decoration: auto;
            text-decoration: auto;
    text-transform: uppercase;
    backface-visibility: hidden;
    letter-spacing: 3px;
    /* width: 71%; */
    font-size: 20px;
  }
  .position {
    margin-top: -76% !important;
  }
}

@media (max-width: 440px) {
  .carousel-item {
    height: 45vh;
  }

  #filaIndex {
    display: grid;
    grid-gap: 1rem;
    grid-gap: 1rem;
    gap: 1rem;
    grid-column-gap: 1rem;
    column-gap: 1rem;
    grid-auto-rows: auto;
    grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
    justify-content: center;
    align-items: center;
    justify-items: center;
    align-content: center;
  }
  #Boton {
    background-color: black;
    border: black;
    background-color: #000000;
    color: #ffffff;
    -webkit-text-decoration: auto;
            text-decoration: auto;
    text-transform: uppercase;
    backface-visibility: hidden;
    letter-spacing: 3px;
    /* width: 71%; */
    font-size: 18px;
  }
  .position {
    margin-top: -76% !important;
  }
}

/*Ajuste link concurso */
@media (max-width: 1399px){
  .centradoBotonConcurso {
    top: 41vw;
  }
}

@media (max-width: 769px){
  .centradoBotonConcurso {
    top: 62vw;
  }
}

@media (max-width: 576px){
  .centradoBotonConcurso {
    top: 62vw;
  }
}

.promo-main {
  display: flex;
  position: fixed;
  width: 100%;
  z-index: 10;
  margin-top: 10rem;
  flex-direction: column;
  align-items: center;
}

.promo-main div {  
  position: fixed;
  width: 22rem;
  display: flex;
  justify-content: flex-end;
}

.promo-main button {  
  padding: 0.5em;
  background-color: transparent;
  color: white;
  font-size: 1.5rem;
}

@media (max-width: 400px) {
  .promo-main {
    margin-top: 10vh;
  }
}
.input_btn {
  border: solid 1px gray;
  font-size: 1.8rem;
  width: 150px;
  text-align: center;
}

.div_btn {
  display: flex;
  flex-direction: column;
}
