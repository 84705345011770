#MenuFiltros {
  width: 30px;
  height: 30px;
  object-fit: contain;
  background-repeat: no-repeat;
}

#FiltrosMovil {
  height: 90vh;
  width: 100vw;
  background-color: rgba(255, 255, 255);
  box-shadow: 2px 2px 5px #999;
  z-index: 2000;
  transition: 1s;
  overflow-y: auto;
  overflow-x: hidden;
}

#Mercado {
  font-size: 1.8em;
  margin-left: 20px;
}

#BotonesRedesMenu {
  height: 40px;
  width: 40px;
}

#BotonInicio {
  margin-top: -20px;
  height: 40px;
  width: 40px;
}

/* .lupa {
  position: relative;
  top: 10px;
  left: 12px;
  height: 20px;
  width: 24px;
  display: flex;
} */

/* .zoomBuscar {
  transform: scale(1) !important;
  transition: transform 0.2s;
}

.zoomBuscar:hover {
  transform: scale(1.4) !important;
}
.zoomBuscarMovil {
  transform: scale(0.6) !important;
  transition: transform 0.2s;
}

.zoomBuscarMovil:hover {
  transform: scale(1) !important;
} */

#botonCerrar {
  position: absolute;
  top: 3%;
  right: 6%;
  font-size: 1.5em;
  z-index: 2500;
}

#headerWeb {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 12%;
  padding: 0;
  margin: 0;
  z-index: 500;
}

.FuenteBebas {
  font-family: "Bebas";
}

.FuenteHelvetica {
  font-family: "Helvetica";
}

#Flecha {
  position: absolute;
  top: 80px;
  right: -15px;
  width: 100px;
  height: 40px;
  display: flex;
}

div .cajaCarrito {
  position: absolute;
  top: 110%;
  right: 0%;
  width: 19.2rem;
  background-color: #ffffff;
  z-index: 1000;
  box-shadow: 2px 2px 5px #999;
  margin-bottom: 0px;
  padding-bottom: 0px;
  height: 275px;
}

div .cajaSesion {
  position: absolute;
  top: 50px;
  left: -100px;
  width: 200px;
  height: 100px;
  background-color: #ffffff;
  z-index: 1000;
  box-shadow: 2px 2px 5px #999;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.img-producto-carrito {
  width: 135px;
  /* height: 200px; */
  margin-left: 0px;
  margin-right: 0px;
  padding-right: 0px;
}

.cartaCarrito {
  margin-top: 10px;
}

.textoCarrito {
  margin-left: 0px;
  font-weight: bold;
  font-size: 13px;
  font-family: Helvetica;
}

#TotalCarrito {
  font-weight: bold;
  font-family: Bebas;
  font-size: 23px;
}

#botonPago {
  font-weight: bold;
  margin-top: 10px;
  margin-right: 40px;
}

.espacioPago {
  display: flex;
  align-content: center;
  justify-content: space-around;
  align-items: center;
  position: relative;
  bottom: 0;
}

.SinDecoracionHeader {
  /*Fondo y decoracion*/
  padding: 0;
  border: 0;
  background: none;
  text-decoration: none;
  margin-right: 20px;
  outline: none;
}

.SinDecoracionHeader:focus {
  /*Fondo y decoracion*/
  padding: 0;
  border: 0;
  background: none;
  text-decoration: none;
  margin-right: 20px;
  outline: none;
}

#productosCarro {
  position: absolute;
  overflow: auto;
  height: 220px;
  overflow-x: hidden;
}

.botonBorrar {
  position: relative;
  width: 29px;
  height: 35px;
}

@font-face {
  font-family: "Bebas";
  src: url("../../../../static/Fonts/BEBASKAI.OTF");
  font-display: swap;
}

@font-face {
  font-family: "Helvetica";
  src: url("../../../../static/Fonts/HELVETICALTSTD-LIGHT.OTF");
  font-display: swap;
}

#LineaCarrito {
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 13px 30px;
  background-repeat: no-repeat;
  background-size: 75px 1px;
  color: black;
}

.LineaEfecto {
  background: none;
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 4px;
  transition: background-size 0.3s;
  background-color: #ffffff00;
}

.LineaEfecto:hover,
.LineaEfecto:focus {
  background-size: 100% 4px;
}

#bienvenido {
  width: 100%;
  font-family: "Bebas";
  font-size: 25px;
}

#nadaEnElCarrito {
  font-family: "Bebas";
  font-size: 30px;
  position: relative;
  margin: 20%;
}

#BotonInicioMenuMayoristas {
  position: absolute;
  right: 0%;
  bottom: 0%;
}

.texto-IniciarSesionFiltroMayoristas {
  margin-top: 20%;
  margin-right: 10px;
  font-size: 20px;
}

.texto-AyudaFiltroMayoristas {
  margin-top: 20%;
  margin-left: 20px;
  font-size: 20px;
}

/**********************************/

#FiltrosWeb {
  display: none;
}

#Buscar {
  display: none;
}

#BarraFiltro {
  background-color: #d8d8d800;
  border: none;
  border-bottom: 1.8px solid;
  font-size: 21px;
  outline: none;
  margin-left: 2%;
  padding: 0;
  width: 140px;
}

/* #espacioLogo {
  position: absolute;
  top: 5%;
  left: 50%;
  display: flex;
  transform: translate(-50%, 0%);
  -webkit-transform: translate(-50%, 0%);
  align-items: center;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
} */
/* 
#ImagenCroatta {
  height: 70px;
  width: 170px;
  object-fit: contain;
} */

.texto-Ayuda {
  display: none;
}

.texto-IniciarSesion {
  display: none;
}

.imagen-IniciarSesion {
  display: none !important;
}

#BotonInicioMenu {
  position: absolute;
  right: 0%;
  bottom: 0%;
}
div .cajaSesionMenu {
  position: absolute;
  top: -70px;
  left: -200px;
  width: 200px;
  height: 100px;
  background-color: #ffffff;
  z-index: 1000;
  box-shadow: 2px 2px 5px #999;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

/* .zoomBuscarMovil {
  transform: scale(0.6) !important;
  transition: transform 0.2s;
}

.zoomBuscarMovil:hover {
  transform: scale(1) !important;
} */

/* .lupaMovil {
  position: absolute;
  left: 100%;
  height: 105%;
  width: 17%;
  display: flex;
} */

.texto-AyudaFiltro {
  margin-top: 20%;
  margin-left: 20px;
  font-size: 20px;
}

.texto-IniciarSesionFiltro {
  margin-top: 20%;
  margin-right: 10px;
  font-size: 20px;
}

.carrito {
  position: absolute;
  left: 55%;
  top: 1%;
}

/* #ImagenBolsa {
  margin-left: 10px;
  margin-top: 5px;
  position: absolute;
  right: 1%;
  top: 1%;
  object-fit: contain;
  height: 35px;
  width: 37px;
} */

#Bolsa {
  position: absolute;
  height: 45px;
  width: 150px;
  margin-top: 17px;
  right: 1%;
  display: flex;
  font-size: 25px;
}

/* #CantidadCarrito {
  position: absolute;
  left: 70%;
  top: 15%;
  transform: translate(-67%, 0%);
  -webkit-transform: translate(-67%, 0%);
  font-weight: bold;
  margin-top: 10px;
  font-size: 15px;
} */

/* Mayoristas Seccion */
#ImagenCroattaMayoristas {
  height: 60px;
  width: 170px;
  object-fit: contain;
}

#espacioLogoMayoristas {
  position: absolute;
  top: 2px;
  left: 50%;
  transform: translate(-50%, 0%);
  -webkit-transform: translate(-50%, 0%);
}

#FiltrosMayoristas {
  height: 100vh;
  width: 100vw;
  background-color: rgba(255, 255, 255);
  z-index: 2000;
  transition: 1s;
  overflow-y: auto;
  overflow-x: hidden;
}

#MenuFiltrosMayoristas {
  margin-left: 10px;
  margin-top: 25px;
  width: 30px;
  height: 30px;
  object-fit: contain;
  background-repeat: no-repeat;
}

#botonCerrarMayoristas {
  position: absolute;
  top: 3%;
  right: 6%;
  font-size: 1.5em;
  z-index: 2500;
}
