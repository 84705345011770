.content {
  display: none;
  justify-content: center;
  gap: 5vw;
  z-index: 12;
}

.button_slide {
  color: rgb(0, 0, 0);
  background-color: rgba(255, 255, 255, 0.5);
  border: 2px solid rgb(0, 0, 0);
  padding: 0px 5px;
  display: inline-block;
  font-size: 3.5vw;
  cursor: pointer;
  box-shadow: inset 0 0 0 0 #cc00ff94;
  -webkit-transition: ease-out 0.4s;
  -moz-transition: ease-out 0.4s;
  transition: ease-out 0.4s;
}

.slide_right {
  box-shadow: inset 400px 0 0 0 #000000;
  color: rgb(255, 255, 255);
}

@media (max-width: 767px) {
  .content {
    display: flex;
  }
}