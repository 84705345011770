.transicion {
  overflow: hidden;
}

.img-producto {
  object-fit: cover;
  transition: transform 2s ease-in-out;
}

.img-producto:hover {
  transform: scale(1.05);
}

.variantes {
  margin-top: 5px !important;
  margin: 2vw;
  height: 3em;
}

.variantes img {
  margin: 1px;
  width: 2.5em;
  /* border-radius: 20px; */
  border: solid 1px rgb(177, 177, 177);
}

.variantes p {
  margin: 0;
  font-family: Poppins-Light;
  font-weight: bold;
  font-size: large;
}

.precios {
  display: flex;
  flex-direction: row;
}

.descuento {
  margin-left: 18px !important;
  font-family: Poppins-Light;
  font-weight: bold;
  color: red;
  text-decoration: line-through;
  font-size: medium !important;
}

.contenedor-compra {
  position: relative;
  z-index: 10;
  background: white;
  text-align: center;
}

.btn-compra {
  margin-top: 5px;
  background-color: white;
  border: solid 1px black;
  border-radius: 50px;
  padding-left: 8px;
  padding-right: 8px;
  font-family: Poppins-Bold;

}

.btn-compra:hover {
  background-color: rgb(0, 0, 0);
  color: white;
}

.prev {
  color: red;
  text-decoration: line-through;
  font-size: medium !important;
}

@media (max-width: 400px) {
  .modal-compra {
    margin-top: 10vh;
  }
  .variantes img {
    width: 1.8em;
  }
}