.input_btn {
  border: solid 1px gray;
  font-size: 1.8rem;
  width: 150px;
  text-align: center;
}

.div_btn {
  display: flex;
  flex-direction: column;
}