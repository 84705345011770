/* ESTILOS NORMALES (para escritorios muy grandes)
   ========================================================================= */
.lupa {
  height: 20px;
}
#BuscarFiltro {
  position: absolute;
  top: 4%;
  margin-left: 5%;
  margin-right: 2%;
  text-align: center;
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.contenedorCarrito {
  position: relative;
  display: inline-block;
  text-align: center;
}
.texto-encima {
  position: absolute;
  top: 10px;
  left: 10px;
}
.centrado {
  position: absolute;
  top: 42.5%;
  left: 56%;
  transform: translate(-50%, -50%);
  font-weight: bold;
}

.centerHeader {
  display: flex;
  position: fixed;
  flex-direction: row;
  /* align-content: center; */
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.centerHeaderamd {
  display: none;
}

.centerHeaderExtra {
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  z-index: 200;
  padding-right: 0.5rem;
}

#SecondHeader {
  background-color: black;
  height: 2.5rem;
  min-width: 100%;
  width: 90%;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: fixed;
  top: 0px;
  z-index: 10;
  overflow: hidden;
}

#SecondHeaderamd{
  display: none;
}

#IconHeader {
  position: absolute;
  right: 77%;
  top: -18%;
  object-fit: contain;
}
#IconHeaderMayo {
  position: relative;
  font-family: Poppins-SemiBoldItalic;
  margin: 0%;
  font-size: 0.72em;
  padding: 6px;
  margin-right: 5px;
  background-color: black;
  color: white;
}

#IconHeaderMayo:hover {
  background-color: white;
  color: black;
}

.botonHeader {
  width: 34px;
}

.RedesSociales {
  height: 33px;
  margin: 1%;
  padding: 4px;
}
.barraHeader {
  color: white;
  font-size: 27px;

  letter-spacing: 5px;
}

#ImagenBolsa {
  margin-left: 10px;
  position: absolute;
  right: 1%;
  top: -23%;
  object-fit: contain;
  height: 35px;
  width: 37px;
}

#ImagenCroatta {
  height: 1.7rem;
  width: 100%;
  object-fit: contain;
}

.CantidadCarrito {
  font-weight: bold;
  font-size: 12px;
}
#espacioLogo {
  position: absolute;
  display: flex;
  width: 100%;
  /* align-content: center; */
  justify-content: center;
  align-items: center;
  /* z-index: -1 !important; */
}

.textHeader {
  margin: 0;
  margin-left: 3vw;
  margin-right: 3vw;
  text-align: center;
  font-family: "Arial_Bold";
  color: white;
  letter-spacing: "1px";
}

.btn-active::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, red, blue, deeppink, blue, red, blue, deeppink, blue);
    background-size: 800%;
    filter: blur(8px);

}

/* RESPONSIVE
   ========================================================================= /
 
/ Tablets en horizonal y escritorios normales
   ------------------------------------------------------------------------- */
@media (min-width: 768px) and (max-width: 1199px) {
  .botonHeader {
    width: 28px;
  }

  #IconHeader {
    position: absolute;
    right: 71%;
    top: -23%;
    object-fit: contain;
  }

  .barraHeader {
    color: white;
    font-size: 25px;
    letter-spacing: 4px;
  }
  /* #espacioLogo {
    position: absolute;
    left: 49%;
    display: flex;
    transform: translate(-50%, 0%);
    -webkit-transform: translate(-50%, 0%);
    align-items: center;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
  } */
}

/* Móviles en horizontal o tablets en vertical
   ------------------------------------------------------------------------- */
@media (max-width: 767px) {
  .botonHeader {
    width: 28px;
  }

  /* #espacioLogo {
    position: absolute;
    left: 30%;
    display: flex;
    transform: translate(-50%, 0%);
    -webkit-transform: translate(-50%, 0%);
    align-items: center;

    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
  } */
  #ImagenCroatta {
    width: 100%;
  }
  #IconHeader {
    position: absolute;
    right: 77%;
    top: -33%;
    object-fit: contain;
  }
  #ImagenBolsa {
    position: absolute;
    right: 1%;
    top: -28%;
    object-fit: contain;
    height: 35px;
    width: 37px;
  }
  .CantidadCarrito {
    font-weight: bold;
    font-size: 10px;
  }
  .barraHeader {
    color: white;
    font-size: 22px;
    letter-spacing: 3px;
  }
   .oculto {
    display: none !important;
  } 

  .RedesSociales {
    height: 30px;
    margin: 1%;
    padding: 2px;
  }
}

/* Móviles en vertical
   ------------------------------------------------------------------------- */
@media (max-width: 480px) {
  .botonHeader {
    width: 28px;
  }

  .barraHeader {
    color: white;
    font-size: 15px;
    letter-spacing: 2px;
  }
  .oculto {
    display: none !important;
  }
}

@media (max-width: 520px) {
   #IconHeaderMayo {
    display: none;
  }
}


@media (max-width: 440px) {
  .botonHeader {
    width: 26px;
  }
 

  .oculto {
    display: none !important;
  }
}
@media (max-width: 320px) {
  .botonHeader {
    width: 24px;
  }

  #IconHeader {
    position: absolute;
    right: 77%;
    top: -40%;
    object-fit: contain;
  }
  /* #espacioLogo {
    position: absolute;
    left: 36%;
    display: flex;
    transform: translate(-50%, 0%);
    -webkit-transform: translate(-50%, 0%);
    align-items: center;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
  } */
  #ImagenBolsa {
    position: absolute;
    right: 1%;
    top: -30%;
    object-fit: contain;
    height: 28px;
    width: 37px;
  }

  .oculto {
    display: none !important;
  }
}
